import React from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Location from '../../assets/Icon_lage.svg'
import ObjectDesc from '../../assets/Icon_Objektbeschreibung.svg'
import Equipment from '../../assets/Icon_ausstattung.svg'
import Others from '../../assets/Icon_sonstiges.svg'
//import NotSuitableFor from '../../assets/Icon_nicht-geeignet.svg'
import Contect from '../../assets/Icon_Kontakt.svg'

function ObjectDetails({content}) {

    return (
        <Container className='d-flex flex-row flex-wrap'>
            <Col className={styles.col}>
                <Row className={styles.title}>
                    <p><img src={Location} alt='' /> Lage</p>
                </Row>
                <Row className={styles.location}>
                    <p>{content.locationDescr}</p>
                </Row>
                <Row className={styles.title}>
                    <p><img src={ObjectDesc} alt='' /> Objektbeschreibung</p>
                </Row>
                <Row className={styles.desc}>
                    <p>{content.description}</p>
                </Row>
                <Row className={styles.title}>
                    <p><img src={Equipment} alt='' /> Auststattung</p>
                </Row>
                <Row className={styles.equip}>
                    <p>{content.facilitiesDescr}</p>
                </Row>
            </Col>
            <Col className={styles.col}>
                <Row className={styles.title}>
                    <p><img src={Others} alt='' /> Sonstiges</p>
                </Row>
                <Row className={styles.content}>
                    <p>{content.other}</p>
                </Row>
                {/*It isn't needed atm*/}

                {/*<Row className={styles.title}>
                    <p><img src={NotSuitableFor} alt='' /> Nicht geeignet für</p>
                </Row>
                <Row className={styles.content}>
                    <p>{content.exclusion}</p>
                </Row>*/}
                <Row className={styles.title}>
                    <p><img src={Contect} alt='' /> Kontakt</p>
                </Row>
                <Row className={styles.content}>
                    <table className={styles.table}>
                        <tr>
                            <td><strong>Ansprechpartner</strong></td>
                            {/*<td>{content.prename} {content.surname}</td>*/}
                            <td>Grupe Maklergesellschaft für Immobilien mbH</td>
                        </tr>
                        <tr>
                            <td><strong>Telefon</strong></td>
                            {/*<td>{content.phone}</td>*/}
                            <td>+49(30) 890 696 8-0</td>
                        </tr>
                        <tr>
                            <td><strong>Fax</strong></td>
                            {/*<td>{content.mail}</td>*/}
                            <td>+49(30) 890 696 8-13</td>
                        </tr>
                    </table>
                </Row>
            </Col>
        </Container>
    )
}

export default ObjectDetails;
