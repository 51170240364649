import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import filter_icon from '../../assets/Icon_filter.svg';
import Button from 'react-bootstrap/Button';
import useLanguageContext from "../../context/useLanguageContext";

function ObjectFilter({setFilter, objects}) {
    const { language } = useLanguageContext();

    function stringComparison(a = 'a', b = 'b') {
        a = a.toLowerCase();
        a = a.replace(/ä/g, 'a');
        a = a.replace(/ö/g, 'o');
        a = a.replace(/ü/g, 'u');
        a = a.replace(/ß/g, 's');

        b = b.toLowerCase();
        b = b.replace(/ä/g, 'a');
        b = b.replace(/ö/g, 'o');
        b = b.replace(/ü/g, 'u');
        b = b.replace(/ß/g, 's');

        return (a === b) ? 0 : (a > b) ? 1 : -1;
    }

    //grouping of locations
    let placeFilter = [];
    objects && (placeFilter = objects.reduce((r, e) => {
        if (e.location != null && Array.isArray(e.location)) {
            const locations = e.location;
            locations.forEach(l => {
                if (!r[l]) {
                    r[l] = []
                }
                r[l].push(e);
            });
            return r;
        } else if (e.location != null) {
            const key = e.location;
            if (!r[key]) r[key] = []
            r[key].push(e);
        }
        return r;
    }, {}))

    const result = Object.entries(placeFilter).sort(function(a, b){
        return a.toString().localeCompare(b.toString());
    })
        .map(([key]) => {
            return (
                <option value={key}>{key}</option>
            )
        });

    const [value, setValue] = useState('');
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [art, setArt] = useState('')

    useEffect(() => {
        if(objects[0] && objects[0].type === '1') {
            if (language === 'en') {
                setArt('Store area')
            }
            else {
                setArt('LadenFläche')
            }
        }
        else {
            if (language === 'en') {
                setArt('Office area')
            }
            else {
                setArt('Gewerbefläche')
            }
        }
    }, [objects, language])

    let filter = {};
    objects && (filter = objects
            .filter(p => min === '' ? p.area2 >= min : p.area2 >= min*0.9) //filter minimum
            .filter(p => max === '' ? p.area2 >= max : p.area2 <= max*1.1) //filter maximum
            .filter(p => {
                if (value !== '') {
                    if (Array.isArray(p.location)) {
                        return p.location.includes(value);
                    } else {
                        return p.location === value
                    }
                } else {
                    return p.location
                }
            })
            .map(props => ({
                id: props.id,
                title: props.title,
                pic: props.pic,
                area2: props.area2,
                net: props.net,
                type: props.type
            }))
    );

    //reseting the values
    function resetFilter () {
        setValue('');
        setMin('');
        setMax('');
        setFilter(objects);
    }

    return (
        <Container className={styles.container}>
            <form>
                <Row className={styles.row}>
                    <Form.Group as={Col} controlId='formGridCity'>
                        <Form.Label className={styles.title}>{language === 'en' ? 'PLACE' : 'ORT'}</Form.Label>
                        <Form.Control className={styles.select} as='select' onChange={e => setValue(e.target.value)}>
                            <option value=''>{language === 'en' ? 'Please Select' : 'Bitte auswählen'}</option>
                            {result}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId='formGridShoparea'>
                        <Form.Label className={styles.title}>{art.toUpperCase()}</Form.Label>
                        <Row>
                            <Col xs={5}><Form.Control as='input' onChange={e => setMin(e.target.value)} value={min} className={styles.select} placeholder={language === 'en' ? 'Min. Size' : 'Min. Größe'} /></Col>
                            <div className={styles.unit}>{'m²'}</div>
                            <Col xs={1} className={styles.text}>{language === 'en' ? 'to' : 'bis'}</Col>
                            <Col xs={5}><Form.Control as='input' onChange={e => setMax(e.target.value)} value={max} className={styles.select} placeholder={language === 'en' ? 'Max. Size' : 'Max. Größe'} /></Col>
                            <div className={styles.unit}>{'m²'}</div>
                        </Row>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Row>
                            <Button className={styles.btn} onClick={() => setFilter(filter) }>{language === 'en' ? 'FILTER' : 'FILTERN'}</Button>
                            <Button type='reset' className={styles.btn} onClick={() => resetFilter() }><img alt='filter-icon' src={filter_icon}/>{language === 'en' ? 'RESET FILTER' : 'FILTER ZURÜCKSETZEN'}</Button>
                        </Form.Row>
                    </Form.Group>
                </Row>
            </form>
        </Container>
    )
}

export default ObjectFilter;
