import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import useLanguageContext from "../../context/useLanguageContext";
import styles from './index.module.scss';

export function ContactInfo ({contact}) {
    const { language } = useLanguageContext();

    return(
        <Container>
            <Row style={{fontSize: '1.25rem'}}>
                <Col sm>
                    <p style={{fontSize: '1.5rem'}}>{language === 'en' ? 'Address' : 'Adresse'}</p>
                    <p className={styles.address}>{contact.address}</p>
                </Col>
                <Col sm>
                    <p style={{fontSize: '1.5rem'}}>{language === 'en' ? 'Office time' : 'Bürozeiten'}</p>
                    <p>{contact.officeTime}</p>
                </Col>
                <Col sm>
                    <p style={{fontSize: '1.5rem'}}>{language === 'en' ? 'Contact' : 'Kontakt'}</p>
                    <div>E-Mail:</div>
                    <p><a href={`mailto:${contact.email}`}>{contact.email}</a></p>
                    <div>Telefon:</div>
                    <p>{contact.phone}</p>
                    {/*<p>{language === 'en' ? 'Managing partners:' : 'Geschäftsführende Gesellschafter:'}</p>
                    <p>{contact.ceo}</p>*/}
                </Col>
            </Row>
        </Container>
    )
}

export default ContactInfo;
