import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import Heading from '../Heading';
import useLanguageContext from '../../context/useLanguageContext';
import {
  propFormGerman,
  propFormEnglish,
} from '../../models/propertyFormModel';
import axios from 'axios';
import useApiDomainContext from '../../context/useApiDomainContext';
import Button from 'react-bootstrap/Button';
import {
  formValMsgGerman,
  formValMsgEnglish,
} from '../../models/formValidationModel';
import SpinnerComponent from '../SpinnerComponent';
import {Link} from "react-router-dom";

function PropertyForm({ pdf }) {
  const { language } = useLanguageContext();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
  });
  const [form, setForm] = useState(propFormGerman);
  const { apiDomain } = useApiDomainContext();
  const [formVal, setFormVal] = useState(formValMsgGerman);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (language === 'en') {
      setForm(propFormEnglish);
      setFormVal(formValMsgEnglish);
    } else {
      setForm(propFormGerman);
      setFormVal(formValMsgGerman);
    }
  }, [language]);

  const handleReset = () => {
    formRef.current.reset();
    recaptchaRef.current.reset();
    setShowSpinner(false);
  };

  function createCaptcha(value) {
    setDisableButton(false);
  }

  const onSubmit = (data) => {
    //console.log('DATA', data);
    setIsError(false);
    setDisableButton(true);
    setShowSpinner(true);
    setIsSuccess(false);

    let gender = '';
    if (data.salutation === form.salutation[1]) {
      gender = 'male';
    } else if (data.salutation === form.salutation[0]) {
      gender = 'female';
    }

    let weAre = '';
    if (data.weAre === form.owner) {
      weAre = 'owner';
    } else if (data.weAre === form.management) {
      weAre = 'propertyManager';
    } else if (data.weAre === form.pretenant) {
      weAre = 'previousTenant';
    }

    const bodyFormData = new FormData();
    bodyFormData.set('type', 'vacant');

    bodyFormData.set('propertyLocation', data.city);
    bodyFormData.set('propertyStreet', data.street);
    bodyFormData.set('salesArea', data.salesArea);
    bodyFormData.set('secondaryArea', data.ancillaryArea);
    bodyFormData.set('basementArea', data.basementArea);
    bodyFormData.set('freeFrom', data.freeFrom);
    bodyFormData.set('netRent', data.netRent);
    bodyFormData.set('heatingCosts', data.heatingCosts);
    bodyFormData.set('operatingCosts', data.operatingCosts);
    bodyFormData.set('operatingCosts', data.operatingCosts);
    bodyFormData.set('heatingType', data.heatingType);
    bodyFormData.set('info', data.propertyDescription);

    bodyFormData.set('salut', gender);
    bodyFormData.set('company', data.company);
    bodyFormData.set('prename', data.firstname);
    bodyFormData.set('surname', data.lastname);
    bodyFormData.set('street', data.contactStreet);
    bodyFormData.set('location', data.contactCity);
    bodyFormData.set('zip', data.postCode);
    bodyFormData.set('mail', data.mail);
    bodyFormData.set('weAre', weAre);
    bodyFormData.set(`phone[0][label]`, 'Fax');
    bodyFormData.set(`phone[0][value]`, data.fax);
    bodyFormData.set(`phone[1][label]`, 'Arbeit');
    bodyFormData.set(`phone[1][value]`, data.phone);

    axios
      .post(`${apiDomain}/wp-json/flowdesk/customer`, bodyFormData)
      .then((response) => {
        //console.log('RESPONSE FROM BACKEND', response);
        setDisableButton(false);

        if (response.data.id) {
          setIsError(false);
          handleReset();
          setIsSuccess(true);
        } else {
          setIsError(true);
          setIsSuccess(false);
          setShowSpinner(false);
        }
      })
      .catch((error) => {
        console.error('contact form error', error);
        setDisableButton(false);
        setIsError(true);
        setIsSuccess(false);
        setShowSpinner(false);
      });
  };

  return (
    <Container className={styles.container}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
        id='propertyForm'
        ref={formRef}
      >
        <Row className={styles.heading}>
          <Heading
            className={styles.heading}
            heading={form.heading1}
            fontSize={60}
          />
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='city'>
              <Form.Label>{form.city}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='city'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.city && (
                <div className={styles.error}>{errors.city.message}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='street'>
              <Form.Label>{form.street}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='street'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.street && (
                <div className={styles.error}>{errors.street.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='salesArea'>
              <Form.Label>{form.salesArea}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='salesArea'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                })}
              />
              {errors.salesArea && (
                <div className={styles.error}>{errors.salesArea.message}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='ancillaryArea'>
              <Form.Label>{form.ancillaryArea}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='ancillaryArea'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                })}
              />
              {errors.ancillaryArea && (
                <div className={styles.error}>
                  {errors.ancillaryArea.message}
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='basementArea'>
              <Form.Label>{form.basementArea}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='basementArea'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                })}
              />
              {errors.basementArea && (
                <div className={styles.error}>
                  {errors.basementArea.message}
                </div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='freeFrom'>
              <Form.Label>{form.freeFrom}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='freeFrom'
                ref={register({
                })}
              />

              {errors.freeFrom && (
                <div className={styles.error}>{errors.freeFrom.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='netto'>
              <Form.Label>{form.netto}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='netRent'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                })}
              />
              {errors.netRent && (
                <div className={styles.error}>{errors.netRent.message}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='heatingCosts'>
              <Form.Label>{form.heatingCosts}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='heatingCosts'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                })}
              />
              {errors.heatingCosts && (
                <div className={styles.error}>
                  {errors.heatingCosts.message}
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='operatingCosts'>
              <Form.Label>{form.operatingCosts}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='operatingCosts'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                })}
              />
              {errors.operatingCosts && (
                <div className={styles.error}>
                  {errors.operatingCosts.message}
                </div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='heatingType'>
              <Form.Label>{form.heatingType}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='heatingType'
                ref={register({
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                })}
              />
              {errors.heatingType && (
                <div className={styles.error}>{errors.heatingType.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={8}>
            <Form.Group controlId='propertyDescription'>
              <Form.Label>{form.propertyDescription}</Form.Label>
              <Form.Control
                className={styles.field}
                as='textarea'
                type='text'
                rows='3'
                name='propertyDescription'
                ref={register({
                  maxLength: {
                    value: 1000,
                    message: formVal.maxLength1000,
                  },
                  minLength: {
                    value: 8,
                    message: formVal.minLength8,
                  },
                })}
              />
              {errors.propertyDescription && (
                <div className={styles.error}>
                  {errors.propertyDescription.message}
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        {/** Pflichtfelder text **/}
        {/*<Row className={`justify-content-md-center`} style={{margin: '1rem'}}>*/}
        {/*  <Col md={8}>*/}
        {/*    <p>{form.requiredField}</p>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <Row className={styles.heading} style={{ marginTop: '90px' }}>
          <Heading
            className={styles.heading}
            heading={form.heading2}
            fontSize={60}
          />
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='salutation'>
              <Form.Label>{form.salutationLabel}</Form.Label>
              <Form.Control
                className={styles.select}
                as='select'
                name='salutation'
                ref={register({ required: true })}
              >
                {form.salutation.map((salutation) => (
                  <option>{salutation}</option>
                ))}
              </Form.Control>
              {errors.salutation && (
                <div className={styles.error}>{errors.salutation.message}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='company'>
              <Form.Label>{form.company}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='company'
                ref={register({
                  maxLength: {
                    value: 300,
                    message: formVal.maxLength300,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.company && (
                <div className={styles.error}>{errors.company.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='firstname'>
              <Form.Label>{form.firstname}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='firstname'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 300,
                    message: formVal.maxLength300,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.firstname && (
                <div className={styles.error}>{errors.firstname.message}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='lastname'>
              <Form.Label>{form.lastname}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='lastname'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 300,
                    message: formVal.maxLength300,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.lastname && (
                <div className={styles.error}>{errors.lastname.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='contactStreet'>
              <Form.Label>{form.contactStreet}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='contactStreet'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 300,
                    message: formVal.maxLength300,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.contactStreet && (
                <div className={styles.error}>
                  {errors.contactStreet.message}
                </div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='postCode'>
              <Form.Label>{form.postCode}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='postCode'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 5,
                    message: formVal.postalCode,
                  },
                  minLength: {
                    value: 5,
                    message: formVal.postalCode,
                  },
                })}
              />
              {errors.postCode && (
                <div className={styles.error}>{errors.postCode.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='contactCity'>
              <Form.Label>{form.contactCity}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='contactCity'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 300,
                    message: formVal.maxLength300,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.contactCity && (
                <div className={styles.error}>{errors.contactCity.message}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='phone'>
              <Form.Label>{form.phone}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='phone'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                  minLength: {
                    value: 8,
                    message: formVal.minLength8,
                  },
                })}
              />
              {errors.phone && (
                <div className={styles.error}>{errors.phone.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>
            <Form.Group controlId='fax'>
              <Form.Label>{form.fax}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='fax'
                ref={register}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId='mail'>
              <Form.Label>{form.mail}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='email'
                name='mail'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: formVal.email,
                  },
                })}
              />
              {errors.mail && (
                <div className={styles.error}>{errors.mail.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={4}>{form.weAre}</Col>
          <Col md={4}>
            <Form.Group controlId='weAre'>
              <Form.Control
                as='select'
                name='weAre'
                ref={register({ required: true })}
              >
                <option>{form.owner}</option>
                <option>{form.management}</option>
                <option>{form.pretenant}</option>
              </Form.Control>
              {errors.weAre && (
                <div className={styles.error}>{errors.weAre.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={`justify-content-md-center`} style={{margin: '1rem'}}>
          <Col md={8}>
            <p>{form.requiredField}</p>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.paragraph1}`}>
          <Col md={8}>
            <p>
              {form.privacy}
            </p>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.formgroup}`}>
          <Col md={8}>
            <Form.Group className={styles.checkbox}>
              <Form.Check
                custom
                className='mb-2'
                type='checkbox'
                id='custom4'
                name='consent'
                ref={register({ required: true })}
                label={<label>{form.privacyCommit1}<Link to='/datenschutz'>{form.privacyLink}</Link>{form.privacyCommit2}</label>}
              />
              {/* <Form.Check custom className="mb-2" type="checkbox" id="custom5" label="Ich bin kein Roboter (Captcha)"
                                        ref={register({required: true})} /> */}
            </Form.Group>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <ReCAPTCHA
            hl={language === 'en' ? 'en' : 'de'}
            sitekey='6Lf_7aIZAAAAAEwc2Y1VRcJXwag6R6c4wmclzhXo'
            onChange={createCaptcha}
            ref={recaptchaRef}
          />
        </Row>
        <Row className='justify-content-center mt-2'>
          <Button
            type='submit'
            className={styles.button}
            disabled={disableButton}
          >
            {form.send}
          </Button>
        </Row>
        {isError && (
          <Row className='justify-content-center'>
            <div className={styles.error}>{form.errorSubmitting}</div>
          </Row>
        )}
        {isSuccess && (
          <Row className='justify-content-center'>
            <div className={styles.success}>{form.successSubmitting}</div>
          </Row>
        )}
      </Form>
      {showSpinner && <SpinnerComponent />}
    </Container>
  );
}

export default PropertyForm;
