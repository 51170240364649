import React from 'react';

export default function modifyTextForLinks(textToCheck) {

    const expression = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;
    //*more code to follow here
    const regex = new RegExp(expression);
    let match = '';
    var splitText = [];
    var startIndex = 0;
    while ((match = regex.exec(textToCheck)) != null) {

        splitText.push({text: textToCheck.substr(startIndex, (match.index - startIndex)), type: 'text'});

        let cleanedLink = textToCheck.substr(match.index, (match[0].length));
        cleanedLink = cleanedLink.replace(/^https?:\/\//, '');
        splitText.push({text: cleanedLink, type: 'link'});

        startIndex = match.index + (match[0].length);
    }
    if (startIndex < textToCheck.length) splitText.push({text: textToCheck.substr(startIndex), type: 'text'});

    const mergedText = splitText.map(x => {
        return (
            <React.Fragment key={x.text}>
                {x.type === 'link'
                    ?
                    <a
                        href={`http://${x.text}`}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {x.text}
                    </a>
                    :
                    <>
                        {x.text}
                    </>
                }
            </React.Fragment>
        )
    });

    return mergedText;
}
