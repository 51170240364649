export const subContactFormGerman = {
    heading: 'KONTAKTANFRAGE',
    headline1: 'Treten Sie mit uns in Kontakt!',
    headline2: 'Wir freuen uns auf Ihre Nachricht!',
    name: 'Name *',
    email: 'E-mail *',
    topic: 'Betreff *',
    message: 'Ihre Nachricht *',
    send: 'Absenden',
    errorSubmitting: 'Es ist ein Fehler aufgetreten. Bitte versuchen is es erneut.',
    successSubmitting: 'Ihre Anfrage wurde erfolgreich verschickt.',
    requiredField: '* Diese Felder müssen ausgefüllt werden.'
};


export const subContactFormEnglish = {
    heading: 'CONTACT REQUEST',
    headline1: 'Get in touch with us.',
    headline2: 'We are looking forward to your message!',
    name: 'Name *',
    email: 'E-mail *',
    topic: 'Subject *',
    message: 'Your message *',
    send: 'Send',
    errorSubmitting: 'Es ist ein Fehler aufgetreten. Bitte versuchen is es erneut.',
    successSubmitting: 'Ihre Anfrage wurde erfolgreich verschickt.',
    requiredField: '* These fields must be filled in.'
};
