import React, {useState, useEffect } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import ImageText from '../../components/ImageText';
import OfferOfDay from '../../components/OfferOfDay';
import useFetchBackend from '../../services/useFetchBackend';
import ReferenceExamples from '../../components/ReferenceExamples';
import Heading from '../../components/Heading';
import TestimonialSlider from '../../components/TestimonialSlider';
import useFetchSuccesses from '../../services/useFetchSuccesses';
import BasicTable from '../../components/BasicTable';
import useLanguageContext from "../../context/useLanguageContext";

function References() {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    const [shopRenter, setShopRenter] = useState({});
    const [landlord, setLandlord] = useState({});
    const backendDailyOffers = useFetchBackend('property?action=search&sale=Miete&full=1');
    const renterSuccessesTestimonials = useFetchSuccesses('?categories=6');
    const lessorSuccessesTestimonials = useFetchSuccesses('?categories=12');
    const [table, setTables] = useState([]);
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress AboutUs page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1470') : await fetchContent('/wp-json/acf/v3/pages/381');
            const acf = json.acf;
            //console.log('TABELLENINHALT', acf.table_1.header.map(title => console.log(title.c) ), acf.table_2)

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            setShopRenter({
                content: acf.shop_references_content,
                image: acf.shop_references_image,
                examples: acf.shop_examples
            });

            setLandlord({
                content: acf.lessor_references_content,
                image: acf.lessor_references_image,
                examples: acf.lessor_examples
            });

            setTables(acf);

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1470') : await fetchContent('/wp-json/wp/v2/pages/381');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            <div className='justify-content-center' style={{marginTop: '40px'}}>
                <Heading fontSize='3em' heading={language === 'en' ? 'RENTAL SUCCESS (tenant)' : 'VERMIETUNGSERFOLGE (Mieter)'} />
            </div>
            <div style={{marginBottom: '40px'}}>
                <TestimonialSlider testimonials={renterSuccessesTestimonials} />
            </div>
            {/*It's not needed atm*/}

            {/*<div style={{marginTop: '120px'}}>
                <ImageText data={shopRenter} />
            </div>*/}
            <div style={{textAlign: 'left'}}>
                <ReferenceExamples examples={shopRenter.examples} />
            </div>
            <div className='justify-content-center' style={{marginTop: '40px'}}>
                <Heading fontSize='3em' heading={language === 'en' ? 'RENTAL SUCCESS (lessor)' : 'VERMIETUNGSERFOLGE (Vermieter)'} />
            </div>
            <div>
                <TestimonialSlider testimonials={lessorSuccessesTestimonials} />
            </div>
            {/*It's not needed atm*/}

            {/*<div style={{marginTop: '120px'}}>
                <ImageText data={landlord} />
            </div>*/}
            <div  style={{marginTop: '40px'}}>
                <BasicTable table={table.table_1} />
            </div>
            <div style={{marginTop: '40px', textAlign: 'left'}}>
                <ReferenceExamples examples={landlord.examples} />
            </div>
            <div style={{marginTop: '40px'}}>
                {backendDailyOffers[0] && <OfferOfDay cards={backendDailyOffers} />}
            </div>
        </div>
    )
}

export default References;
