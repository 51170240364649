import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import ButtonCenter from '../ButtonCenter';
import styles from './index.module.scss';
import Col from 'react-bootstrap/Col';
import useLanguageContext from "../../context/useLanguageContext";
import ButtonExternal from '../ButtonExternal';

function CardOrder(props) {
    const { language } = useLanguageContext();
    return(
        <Container>
            <Row className={styles.box}>
                <Col md={{size:6}} className={styles.card}>
                    <Card className={styles.layer1} style={{backgroundImage: `url(${props.image})`}}>
                        <Card.Body className={styles.layer2}>
                            <Card.Title className={styles.header1}>{props.header1}</Card.Title>
                            <Card.Title className={styles.header2}>{props.header2}</Card.Title>
                            <Card.Text className={styles.paragraph}>{props.paragraph1}
                            </Card.Text>
                           {/* <ButtonCenter position='justify-content-center' value={language === 'en' ? 'CLICK' : 'KLICKEN'} link to='https://handelsstadtplan.grupeimmobilien.de/' /> */}
                           <ButtonExternal value={language === 'en' ? 'CLICK' : 'KLICKEN'} position='justify-content-center' link={'https://handelsstadtplan.grupeimmobilien.de/'} target='_blank'/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default CardOrder;
