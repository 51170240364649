import React from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useLanguageContext from "../../context/useLanguageContext";
import {Link as link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';

function PressPreview({data = {image: '', title: '', date: '', content: '', link: '', weblink: '', pdf: '', id: ''}}) {
    const { language } = useLanguageContext();
    // const year = data.date.slice(0,4)
    // const month = data.date.slice(5,7)
    // const day = data.date.slice(8,10)
    // const newDate = day+'.'+month+'.'+year

return (
    data.image === '' ?
        <Container className={`justify-content-center ${styles['blog-preview-container']}`}>
            <Row>
                <Col className={styles.blog}>
                    {/*remove the date*/}
                    {/*<Row className={styles.date}>{newDate}</Row>*/}

                    {/*remove the link from title*/}
                    {/*<Row as={link} to={`/aktuelles/blogartikel/${data.id}`} className={styles.title}>{data.title}</Row>*/}

                    <Row className={styles.title}>{data.title}</Row>
                    <Row className={styles.content}>{data.content}</Row>
                    {data.link === 'pdf' ?
                        <Row>
                            <Button className={styles.button}>
                                <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={data.pdf} >
                                    {language === 'en' ? 'MORE' : 'MEHR'}
                                </a>
                            </Button>
                        </Row>
                        : data.link === 'website' ?
                            <Row>
                                <Button className={styles.button}>
                                    <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={data.weblink} >
                                        {language === 'en' ? 'MORE' : 'MEHR'}
                                    </a>
                                </Button>
                            </Row>
                            :
                            <Row>
                                <Button className={styles.button}>
                                    <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={`/aktuelles/blogartikel/${data.id}`} >
                                        {language === 'en' ? 'MORE' : 'MEHR'}
                                    </a>
                                </Button>
                            </Row>
                    }
                </Col>
            </Row>
        </Container>
        :
        <Container className={`justify-content-center ${styles['blog-preview-container']}`}>
            <Row>
                <Col md sm={12} className={styles.image}>
                    <img alt='' width='540' height='360' src={data.image} />
                </Col>
                <Col md sm={12} className={styles.blog}>
                    {/*remove the date*/}
                    {/*<Row className={styles.date}>{newDate}</Row>*/}

                    {/*remove the link from title*/}
                    {/*<Row className={styles.title}><a target='_blank' rel="noopener noreferrer" href={`/aktuelles/blogartikel/${data.id}`} >{data.title}</a></Row>*/}

                    <Row className={styles.title}>{data.title}</Row>
                    <Row className={styles.content}>{data.content}</Row>
                    {data.link === 'pdf' ?
                        <Row>
                            <Button className={styles.button}>
                                <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={data.pdf} >
                                    {language === 'en' ? 'MORE' : 'MEHR'}
                                </a>
                            </Button>
                        </Row>
                    : data.link === 'website' ?
                        <Row>
                            <Button className={styles.button}>
                                <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={data.weblink} >
                                    {language === 'en' ? 'MORE' : 'MEHR'}
                                </a>
                            </Button>
                        </Row>
                    :
                        <Row>
                            <Button className={styles.button}>
                                <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={`/aktuelles/blogartikel/${data.id}`} >
                                    {language === 'en' ? 'MORE' : 'MEHR'}
                                </a>
                            </Button>
                        </Row>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default PressPreview;
