import React from 'react';
import styles from './index.module.scss'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function ObjectCard({image, title, content, content2, linkId}) {

    function titleToPath(str) {
        return str
            .toLowerCase()
            .replace(/\u00fc/g, 'ue')
            .replace(/\u00e4/g, 'ae')
            .replace(/\u00f6/g, 'oe')
            .replace(/\u00df/g, 'ss')
            .replace(/ /g, '-')
            .replace(/[^a-z-]/g, '');
    }

    const address = content2.slice(0, 4)

    return (
        <Card className={styles.card}
              as={Link}
              to={`/${titleToPath(address === 'Gewe' ? 'gewerbeangebote' : 'ladenangebote')}-${title !== null ? titleToPath(title) : ''}-${linkId}`}
              target='_blank'>
            <Card.Img className={styles['card-img']} top width='100%' src={image} />
            <Card.Body className={styles['card-body']}>
                <Card.Text className={styles.title}>
                    {title}
                </Card.Text>
                <Card.Text className={styles.content}>
                    <div>{content}</div>
                    <div>{content2}</div>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ObjectCard;
