import React from 'react';
import styles from './index.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function ImageText({data}) {
    return (
        data.image === undefined ?
            <Container className={styles.container}>
                <Row>
                    <Col className='align-content-start'>{data.content}</Col>
                </Row>
            </Container>
            :
            <Container className={styles.container}>
                <Row>
                    <Col md className={styles.image}><img alt='' src={data.image} /></Col>
                    <Col md className='align-content-start'>{data.content}</Col>
                </Row>
            </Container>
    )
}

export default ImageText;
