import React, {useState, useEffect, useRef} from 'react';
import styles from './index.module.scss';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Heading from '../Heading';
import axios from 'axios';
import useApiDomainContext from '../../context/useApiDomainContext';
import { contactFormGerman, contactFormEnglish } from '../../models/contactFormModel';
import useLanguageContext from '../../context/useLanguageContext';
import SpinnerComponent from '../SpinnerComponent';
import {formValMsgEnglish, formValMsgGerman} from "../../models/formValidationModel";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';

export function ContactForm({propertyId}) {
    const {language} = useLanguageContext();
    const {register, handleSubmit, errors} = useForm()
    const {apiDomain} = useApiDomainContext();
    //const [contactError, setContactError] = useState(false);
    const [contactForm, setcontactForm] = useState(contactFormGerman);
    const [formVal, setFormVal] = useState(formValMsgGerman);
    const formRef = useRef(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [isError, setIsError] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (language === 'en') {
            setcontactForm(contactFormEnglish);
            setFormVal(formValMsgEnglish);
        } else {
            setcontactForm(contactFormGerman);
            setFormVal(formValMsgGerman);
        }
    }, [language])

    const handleReset = () => {
        formRef.current.reset();
        recaptchaRef.current.reset();
        setShowSpinner(false);
    };

    function createCaptcha(value) {
        setDisableButton(false);
    }

    function onSubmit(data) {
        setIsError(false);
        setDisableButton(true);
        setShowSpinner(true);
        setIsSuccess(false);

        let chosenWishes = [];
        let gender = '';
        if (data.wish.info) {
            chosenWishes.push('informations')
        }
        if (data.wish.viewing) {
            chosenWishes.push('viewingAppointment')
        }
        if (data.wish.call) {
            chosenWishes.push('recall')
        }
        if (data.salutation === contactForm.salutation[1]) {
            gender = 'male';
        } else if (data.salutation === contactForm.salutation[0]) {
            gender = 'female';
        }

        const bodyFormData = new FormData();
        bodyFormData.set('type', 'request');

        bodyFormData.set('salut', gender);
        bodyFormData.set('prename', data.firstname);
        bodyFormData.set('surname', data.lastname);
        bodyFormData.set('company', data.company);
        bodyFormData.set('zip', data.postCode);
        bodyFormData.set('street', data.street);
        bodyFormData.set('location', data.city);
        bodyFormData.set(`phone[0][label]`, 'Fax');
        bodyFormData.set(`phone[0][value]`, data.fax);
        bodyFormData.set(`phone[1][label]`, 'Arbeit');
        bodyFormData.set(`phone[1][value]`, data.phone);
        bodyFormData.set('mail', data.mail);
        bodyFormData.set('industry', data.branche);
        bodyFormData.set('wishes', chosenWishes.join(','));
        bodyFormData.set('connectorProperty', propertyId);

        axios
            .post(
                `${apiDomain}/wp-json/flowdesk/customer`,
                bodyFormData
            )
            .then(response => {
                //console.log('RESPONSE FROM BACKEND', response)
                setDisableButton(false);

                if (response.data.id) {
                    setIsError(false);
                    handleReset();
                    setIsSuccess(true);
                } else {
                    setIsError(true);
                    setIsSuccess(false);
                    setShowSpinner(false);
                }
            })
            .catch((error) => {
                console.error('contact form error', error);
                setDisableButton(false);
                setIsError(true);
                setIsSuccess(false);
                setShowSpinner(false);
            });
    }

    return (
        <Container className={styles.container}>
            <Form onSubmit={handleSubmit(onSubmit)} ref={formRef} id='contactForm'>
                <Row className={styles.heading}>
                    <Heading className={styles.heading} heading={language === 'en' ? 'CONTACT REQUEST   ' : 'KONTAKTANFRAGE'} fontSize={60} />
                </Row>
                <Row className={styles.formgroup}>
                    <Col md={6}>
                        <Form.Group controlId='salutation'>
                            <Form.Label>{contactForm.salutationLabel.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.select} as='select' name='salutation'
                                          ref={register({required: true})}>
                                {contactForm.salutation.map((salutation) => (
                                    <option>{salutation}</option>
                                ))}
                            </Form.Control>
                            {errors.salutation && (
                                <div className={styles.error}>{errors.salutation.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="company">
                            <Form.Label>{contactForm.company.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="company"
                                          ref={register({
                                              maxLength: {
                                                  value: 300,
                                                  message: formVal.maxLength300,
                                              },
                                              minLength: {
                                                  value: 2,
                                                  message: formVal.minLength2,
                                              },
                                          })} />
                            {errors.company && (
                                <div className={styles.error}>{errors.company.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={styles.formgroup}>
                    <Col sm={6}>
                        <Form.Group controlId="firstname">
                            <Form.Label>{contactForm.firstname.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="firstname"
                                          ref={register({
                                              required: {
                                                  value: true,
                                                  message: formVal.required,
                                              },
                                              maxLength: {
                                                  value: 300,
                                                  message: formVal.maxLength300,
                                              },
                                              minLength: {
                                                  value: 2,
                                                  message: formVal.minLength2,
                                              },
                                          })} />
                            {errors.firstname && (
                                <div className={styles.error}>{errors.firstname.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="lastname">
                            <Form.Label>{contactForm.lastname.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="lastname"
                                          ref={register({
                                              required: {
                                                  value: true,
                                                  message: formVal.required,
                                              },
                                              maxLength: {
                                                  value: 300,
                                                  message: formVal.maxLength300,
                                              },
                                              minLength: {
                                                  value: 2,
                                                  message: formVal.minLength2,
                                              },
                                          })}
                            />
                            {errors.lastname && (
                                <div className={styles.error}>{errors.lastname.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={styles.formgroup}>
                    <Col md={9}>
                        <Form.Group controlId="street">
                            <Form.Label>{contactForm.street.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="street"
                                          ref={register({
                                              required: {
                                                  value: true,
                                                  message: formVal.required,
                                              },
                                              maxLength: {
                                                  value: 300,
                                                  message: formVal.maxLength300,
                                              },
                                              minLength: {
                                                  value: 2,
                                                  message: formVal.minLength2,
                                              },
                                          })}
                            />
                            {errors.street && (
                                <div className={styles.error}>
                                    {errors.street.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={styles.formgroup}>
                    <Col sm={3}>
                        <Form.Group controlId="postCode">
                            <Form.Label>{contactForm.postCode.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="postCode"
                                          ref={register({
                                              required: {
                                                  value: true,
                                                  message: formVal.required,
                                              },
                                              maxLength: {
                                                  value: 5,
                                                  message: formVal.postalCode,
                                              },
                                              minLength: {
                                                  value: 5,
                                                  message: formVal.postalCode,
                                              },
                                          })}
                            />
                            {errors.postCode && (
                                <div className={styles.error}>{errors.postCode.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col sm={9}>
                        <Form.Group controlId="city">
                            <Form.Label>{contactForm.city.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="city"
                                          ref={register({
                                              required: {
                                                  value: true,
                                                  message: formVal.required,
                                              },
                                              maxLength: {
                                                  value: 300,
                                                  message: formVal.maxLength300,
                                              },
                                              minLength: {
                                                  value: 2,
                                                  message: formVal.minLength2,
                                              },
                                          })}
                            />
                            {errors.city && (
                                <div className={styles.error}>{errors.city.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={styles.formgroup}>
                    <Col sm={6}>
                        <Form.Group controlId="phone">
                            <Form.Label>{contactForm.phone.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="phone"
                                          ref={register({
                                              required: {
                                                  value: true,
                                                  message: formVal.required,
                                              },
                                              maxLength: {
                                                  value: 100,
                                                  message: formVal.maxLength100,
                                              },
                                              minLength: {
                                                  value: 8,
                                                  message: formVal.minLength8,
                                              },
                                          })}
                            />
                            {errors.phone && (
                                <div className={styles.error}>{errors.phone.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="fax">
                            <Form.Label>{contactForm.fax.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="fax"
                                          ref={register} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={styles.formgroup}>
                    <Col>
                        <Form.Group controlId="mail">
                            <Form.Label>{contactForm.mail.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="mail"
                                          ref={register({
                                              required: {
                                                  value: true,
                                                  message: formVal.required,
                                              },
                                              maxLength: {
                                                  value: 100,
                                                  message: formVal.maxLength100,
                                              },
                                              pattern: {
                                                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                  message: formVal.email,
                                              }
                                          })}
                            />
                            {errors.mail && (
                                <div className={styles.error}>{errors.mail.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={styles.formgroup}>
                    <Col md={9}>
                        <Form.Group controlId="branche">
                            <Form.Label>{contactForm.industry.toUpperCase()}</Form.Label>
                            <Form.Control className={styles.field} as="input" type="text" name="branche"
                                          ref={register({
                                              required: {
                                                  value: true,
                                                  message: formVal.required,
                                              },
                                              maxLength: {
                                                  value: 300,
                                                  message: formVal.maxLength300,
                                              },
                                              minLength: {
                                                  value: 2,
                                                  message: formVal.minLength2,
                                              },
                                          })}
                            />
                            {errors.branche && (
                                <div className={styles.error}>
                                    {errors.branche.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={styles.formgroup}>
                    <Col>
                        <Form.Group controlId='wishes'>
                            <Form.Label>{contactForm.wish.toUpperCase()}</Form.Label>
                            <div className={styles.checkbox}>
                                <Form.Check custom className='mb-2' type='checkbox' id='custom1'
                                            label={contactForm.wishes[0]} name='wish.info' ref={register} />
                                <Form.Check custom className='mb-2' type='checkbox' id='custom2'
                                            label={contactForm.wishes[1]} name='wish.viewing' ref={register} />
                                <Form.Check custom className='mb-2' type='checkbox' id='custom3'
                                            label={contactForm.wishes[2]} name='wish.call' ref={register} />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{margin: '1rem'}}>
                    <Col>
                        <div>{contactForm.requiredField}</div>
                    </Col>
                </Row>
                <Row className={styles.paragraph}>
                    <Col>
                        <p>
                            Die uns übersendeten Daten werden streng vertraulich behandelt. Während des Datentransportes
                            sind jedoch
                            keine umfassenden Schutzvorkehrungen möglich, somit ist ein rechtswidriger Zugriff Dritter
                            nicht
                            vollkommen auszuschließen. Zum 01.07.1997 trat das neue Multimediagesetz in Kraft. Danach
                            dürfen u.a.
                            personenbezogene Daten nur mit Einwilligung der betreffenden Person gespeichert und
                            weiterverarbeitet
                            werden. Durch Bestätigen Ihrer Eingaben in Formularen mit dem Button "Absenden" o. ä., erklären Sie sich hiermit einverstanden.
                        </p>
                    </Col>
                </Row>
                <Row className={styles.formgroup}>
                    <Col>
                        <Form.Group className={styles.checkbox}>
                            <Form.Check custom className='mb-2' type='checkbox' id='custom4'
                                        name='privacy'
                                        ref={register({required: true})}
                                        label={<span>Ich erkläre mich mit der Verarbeitung meiner persönlichen Daten gemäß der <Link to={'/datenschutz'}>Datenschutzerklärung</Link> einverstanden</span>}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <ReCAPTCHA
                        hl={language === 'en' ? 'en' : 'de'}
                        sitekey='6Lf_7aIZAAAAAEwc2Y1VRcJXwag6R6c4wmclzhXo'
                        onChange={createCaptcha}
                        ref={recaptchaRef}
                    />
                </Row>
                <Row className='justify-content-center'>
                    <Button type='submit' className={styles.button} disabled={disableButton}>
                        {contactForm.send.toUpperCase()}
                    </Button>
                </Row>
                {isError && (
                    <Row className='justify-content-center'>
                        <div className={styles.error}>{contactForm.errorSubmitting}</div>
                    </Row>
                )}
                {isSuccess && (
                    <Row className='justify-content-center'>
                        <div className={styles.success}>{contactForm.successSubmitting}</div>
                    </Row>
                )}
            </Form>
            {showSpinner && <SpinnerComponent />}
        </Container>
    )
}

export default ContactForm;
