import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SuccessCard from '../SuccessCard';
import styles from './index.module.scss';
import useLanguage from '../../context/useLanguageContext';
import ButtonCenter from '../ButtonCenter';

function SuccessCardBlock({content, link}) {

    const {language} = useLanguage();

    for(let i = content.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * i)
        const temp = content[i]
        content[i] = content[j]
        content[j] = temp
    }

    const cards = content.slice(0, 2).map(prop => (
        <Col xs='12' lg='6' key={`${prop.id}`}>
            <SuccessCard image={prop.property_image}
                         title={`${prop.street}`}
                //title={`${prop.street} in ${prop.city} - ${prop.district}`}
                         name={prop.property_company}
                         content={prop.statement}
            />
            <Row className={styles.btn}>
                <ButtonCenter
                    link={link}
                    position='justify-content-center'
                    value={language === 'en' ? 'MORE' : 'MEHR'}
                />
            </Row>
        </Col>
    ));

    return (
        <Container>
            <Row>
                {cards}
            </Row>
        </Container>
    )
}

export default SuccessCardBlock;
