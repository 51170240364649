import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ButtonCenter from '../ButtonCenter';
import styles from './index.module.scss';
import useLanguageContext from "../../context/useLanguageContext";
import ButtonExternal from '../ButtonExternal';

function CardImage({content, link,target}) {
    const { language } = useLanguageContext();
    return (
        <Container className={styles.container}>
            <Card
                style={{
                    backgroundImage: `url(${content.image})`,
                    backgroundSize: '100% 100%'
                }}
                className={styles.layer1}>
                <Card.Body className={styles.layer2}>
                    <Card.Title className={styles.header1}>{content.header1}</Card.Title>
                    <Card.Title className={styles.header2}>{content.header2}</Card.Title>
                    <Card.Text className={styles.paragraph}>{content.content}</Card.Text>
                    
                    <ButtonCenter value={language === 'en' ? 'MORE' : 'MEHR'} position='justify-content-center' link={link} target={target}/> 
                    
                </Card.Body>
            </Card>
        </Container>
    )
}

export default CardImage;
