import React, {useState, useEffect} from 'react';
import CookieConsent from 'react-cookie-consent';
import useLanguageContext from '../../context/useLanguageContext';
import {coCoEnglish, coCoGerman} from '../../models/cookieConfirmationModel';
import { Link } from 'react-router-dom';

function CookieConfiramtion ({setConsent}) {
    const {language} = useLanguageContext();
    const [cookieConfirm, setCookieConfirm] = useState(coCoGerman);

    useEffect(() => {
        if (language === 'en') {
            setCookieConfirm(coCoEnglish);
        }
        else {
            setCookieConfirm(coCoGerman);
        }
    }, [language]);

    return (
        <CookieConsent cookieName='confirmation'
                       location='none'
                       buttonText={cookieConfirm.confirm}
                       style={{
                           textAlign: 'left',
                           position: 'fixed',
                           bottom: '-16px',
                           whiteSpace: 'pre-line',
                           alignItems: 'center'
                       }}
                       buttonStyle={{
                           background: '#FE9900',
                           color: '#fff',
                           fontWeight: '600'
                       }}
                       onAccept={() => setConsent(true)}
                       containerClasses='alert'>
            {cookieConfirm.content}
            <Link to='datenschutz' style={{color: '#FE9900'}}>{cookieConfirm.link}</Link>
        </CookieConsent>
    )
}

export default CookieConfiramtion;
