import React, {useState} from 'react';
import styles from './index.module.scss'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import QuotMarkStart from '../../assets/Symbol_Quotation-mark-start.svg'
import QuotMarkEnd from '../../assets/Symbol_Quotation-mark-end.svg'
import Button from 'react-bootstrap/Button'

function Testimonials({image, name, company, content, bodyBackground}) {
    const [show, setShow] = useState(false);

    function showMore() {
        setShow(true)
    }
    function showLess() {
        setShow(false)
    }

    return (
        <Container className={styles.container}>
            <Row className='justify-content-center mb-3'>
                {image ?
                    <Image className={styles.img} src={image} roundedCircle/>
                    :
                    <div className={styles.imgFill} src={image} roundedCircle/>
                }
            </Row>
            <Row className={styles.body} style={{backgroundColor: bodyBackground}}>
                <Col className={styles['quot-start']}>
                    <Image src={QuotMarkStart} />
                </Col>
                <Col xs={8} className={styles['text-body']}>
                    <Row className={styles.name}>{name}</Row>
                    <Row className={styles.location}>{company}</Row>
                    {show === false ?
                    <Row className={styles.text} key='ka'>
                        {content && content.substring(0,55)} ...
                        <Button className={styles.button} onClick={showMore}>{'Mehr'.toUpperCase()}</Button>
                    </Row>
                        :
                    <Row className={styles.text}>{content}
                        <Button className={styles.button} onClick={showLess}>{'Weniger'.toUpperCase()}</Button>
                    </Row>
                    }
                </Col>
                <Col className={styles['quot-end']}>
                    <Image src={QuotMarkEnd} />
                </Col>
            </Row>
        </Container>
    )
}

export default Testimonials;
