import React, { useState } from "react";
import {ApiDomainContext} from './ApiDomainContext';

function ApiDomainContextProvider({children}) {
    const [apiDomain, setApiDomain]= useState('https://wp.grupeimmobilien.de');

    return (
        <ApiDomainContext.Provider value={{apiDomain, setApiDomain}}>
            {children}
        </ApiDomainContext.Provider>
    );
}

export default ApiDomainContextProvider;
