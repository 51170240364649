import React from 'react';
import styles from './index.module.scss';

function Heading({fontSize, heading,}) {
    return (
        <div className={styles.container}>
            <div className={styles['corner-border']}>
                {heading}
            </div>
        </div>
    )
}

export default Heading;
