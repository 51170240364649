import React from 'react';
import styles from './index.module.scss'
import { Link as LinkRouter }  from 'react-router-dom';

function Link({image, content, link}) {
    return (
        <LinkRouter to={link} className={styles.link}>
            <p><img alt='' src={image} /> {content}</p>
        </LinkRouter>
    )
}

export default Link;
