import React  from 'react';
import styles from './index.module.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NetworkSliderCard from '../NetworkSliderCard';
import PrevArrow from '../../assets/Icon_arrow_left.svg'
import NextArrow from '../../assets/Icon_arrow_right.svg'
import Container from "react-bootstrap/Container";

function NetworkSlider({networks = {image: '', title: '', content: ''}}) {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            >
                <img alt='nextArrow' src={NextArrow} />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            >
                <img alt='prevArrow' src={PrevArrow} />
            </div>
        );
    }

    const cards = networks.map(prop =>
        <div key={prop.id}>
            <NetworkSliderCard
                image={prop.image}
                title={prop.title}
                text={prop.content}
            />
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    };

    return (
        <Container className={styles.container}>
            <Slider {...settings}>
                {cards}
            </Slider>
        </Container>
    );
}

export default NetworkSlider;
