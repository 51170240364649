import React, {useState, useEffect, useRef } from 'react';
import styles from '../Start/index.module.scss';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import ImageText from '../../components/ImageText';
import TextCenter from '../../components/TextCenter';
import Switch from '../../components/Switch';
import ObjectFilter from '../../components/ObjectFilter';
import Heading from '../../components/Heading';
import ObjectResult from '../../components/ObjectResult';
import HeaderTextButton from '../../components/HeaderTextButton';
import SuccessCardBlock from '../../components/SuccessCardBlock';
import useFetchBackend from '../../services/useFetchBackend';
import useFetchSuccesses from "../../services/useFetchSuccesses";
import useLanguageContext from "../../context/useLanguageContext";
import axios from 'axios';

function Rent() {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    const [introduction, setIntroduction] = useState({});
    const [consulting, setConsulting] = useState({});
    const [teaser, setTeaser] = useState({});
    const [shopSearch, setShopSearch] = useState({});
    const [officeSearch, setOfficeSearch] = useState({});
    const rentSuccessesCard = useFetchSuccesses('?categories=10');
    const backendStores = useFetchBackend('property?action=search&type=1&full=1');       // type=1: Ladenangebot
    // const [backendStores, setBackendStores] = useState();
    const backendOffices = useFetchBackend('property?action=search&type=2&full=1');      // type=2: Büroangebot
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 146);
    const [stores, setStores] = useState([]);
    const [offices, setOffices] = useState([]);
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    const storeRef = useRef(null)
    const executeStoreScroll = () => scrollToRef(storeRef)
    const officeRef = useRef(null)
    const executeOfficeScroll = () => scrollToRef(officeRef)


    //old link for HeaderTextButton to Ladengesuche page {/* link='/vermietung/ladengesuche' button={language === 'en' ? 'To store requests' : 'Zu den Ladengesuchen'}*/ }

    // fetch Wordpress Rent page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1508') : await fetchContent('/wp-json/acf/v3/pages/55');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            setIntroduction({
                header: acf.introduction_header,
                content: acf.introduction_content
            })

            setConsulting({
                content: acf.consulting_content
            });

            setTeaser({
                header1: acf.switch_header_1,
                header2: acf.switch_header_2,
                image: acf.switch_image,
            })

            setShopSearch({
                header1: acf.shop_header_1,
                header2: acf.shop_header_2,
                content: acf.shop_content
            })

            setOfficeSearch({
                header1: acf.office_header_1,
                header2: acf.office_header_2,
                content: acf.office_content
            })
        }
        updateState().catch(err => console.error(err));
    }, [language]);

    useEffect(() => {
        async function updateState() {
            setStores(backendStores)
        }
        updateState();
    }, [backendStores]);

    useEffect(() => {
        async function updateState() {
            setOffices(backendOffices)
        }
        updateState();
    }, [backendOffices]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1508') : await fetchContent('/wp-json/wp/v2/pages/55');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    // to test (insert token from WP Flowdesk Integration)
    // useEffect(() => {
    //     async function updateState() {
    //         const response = await axios.get('https://grupe.test.api.flowdesk.cloud/property?full=1&token=');
    //         const json = await response.data;
    //         console.log('IDS', json);
    //         let ids;
    //         if (json['ids']) {
    //             ids = json['ids'].map(id => {
    //                 const object = json.data.property[id];
    //                 if (object['pic'] && object['pic'][0]) {
    //                     const picId = object['pic'][0];
    //                     object.pic = json.data.document[picId]['file']
    //                     return object;
    //                 } else
    //                     return object;
    //
    //             })
    //         } else
    //             ids = [];
    //         setBackendStores(ids);
    //
    //     }
    //     updateState().catch(err => console.error(err));
    // }, []);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            <div style={{marginTop: '20px'}}>
                <TextCenter data={introduction} />
            </div>
            <div style={{marginTop: '0px'}}>
                <TextCenter data={consulting} />
            </div>
            <div style={{marginTop: '20px'}}>
                <Switch content={teaser} onClick1={executeStoreScroll} onClick2={executeOfficeScroll} />
            </div>
            <div style={{backgroundColor: '#F4F4F4', paddingTop: '20px'}}>
                <div style={{marginBottom: '20px'}} id='ladenangebot' ref={storeRef}>
                    <Heading fontSize={styles.fontSize50} heading={language === 'en' ? 'STORE OFFERS' : 'LADENANGEBOTE'}/>
                </div>
                <div style={{marginBottom: '0px'}}>
                    {backendStores &&
                    <ObjectFilter setFilter={setStores} objects={backendStores} />
                    }
                </div>
                <div style={{paddingBottom: '20px'}}>
                    {stores &&
                    <ObjectResult cards={stores} />
                    }
                </div>
            </div>
            <div>
                <HeaderTextButton content={shopSearch} link='/kontakt' button={language === 'en' ? 'Contact us' : 'Kontaktieren Sie uns'}/>
            </div>
            <div style={{backgroundColor: '#F4F4F4', paddingTop: '20px'}}>
                <div style={{marginBottom: '20px'}} ref={officeRef} id='bueroangebot'>
                    <Heading fontSize={styles.fontSize50} heading={language === 'en' ? 'OFFICE OFFERS' : 'GEWERBEANGEBOTE'}/>
                </div>
                <div style={{marginBottom: '0px'}}>
                    <ObjectFilter setFilter={setOffices} objects={backendOffices}/>
                </div>
                <div style={{paddingBottom: '20px'}}>
                    <ObjectResult cards={offices} />
                </div>
            </div>
            <div>
                <HeaderTextButton content={officeSearch} link='/kontakt' button={language === 'en' ? 'Contact us' : 'Kontaktieren Sie uns'} />
            </div>
            <div>
                <div style={{marginTop: '20px'}}>
                    <Heading fontSize={styles.fontSize50} heading={language === 'en' ? 'RENTAL SUCCESSES' : 'VERMIETUNGSERFOLGE'}/>
                </div>
                <div style={{marginTop: '20px', marginBottom: '70px'}}>
                    <SuccessCardBlock content={rentSuccessesCard} link={'/vermietung/vermietungserfolge'}/>
                </div>
            </div>
        </div>
    )
}

export default Rent;
