import React from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Heading from '../Heading';
import CardStandard from '../CardStandard';
import ButtonCenter from '../ButtonCenter';
import useLanguageContext from "../../context/useLanguageContext";

function OfferOfDay({cards}) {
    const { language } = useLanguageContext();
    const random = Math.floor(Math.random() *cards.length);

    return (
        <Container className={styles.container}>
            <Row className='justify-content-center'>
                <Heading heading={language === 'en' ? 'Offer of day' : 'Angebot des Tages'} />
            </Row>
            <Row className={styles.body}>
                <Col md={6}>
                    <Row className={styles.card}>
                        <CardStandard key={`${cards[random].id}`}
                                      image={cards[random].pic}
                                      //header={cards[random].pictureChoice === '1' ? 'Vermietung' : 'Verkauf'}
                                      // header={'Vermietung'}
                                      title={`Angebot ${cards[random].type === '1' ? 'Ladenfläche' : 'Bürofläche'}`}
                                      content={cards[random].title}
                                      linkId={cards[random].id}
                        />
                    </Row>
                    <Row className={styles.btn}>
                        <ButtonCenter
                            position='justify-content-center'
                            value={language === 'en' ? 'MORE' : 'MEHR'}
                            link={'/vermietung'}
                            //link={cards[random].pictureChoice === '1' ? '/vermietung' : '/verkauf'}
                        />
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default OfferOfDay;
