import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ButtonCenter from "../ButtonCenter";
import styles from './index.module.scss';
import Col from 'react-bootstrap/Col';
import useLanguageContext from "../../context/useLanguageContext";
import {Link as link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';

function PressPreviewBlock({press}) {
    const { language } = useLanguageContext();

    const [maxCards, setMaxCards] = useState(3);

    const presses = press.slice(0, maxCards).map(props => (

        props.image === '' ?
            <Container className={`justify-content-center ${styles['blog-preview-container']}`}>
                <Row>
                    <Col>
                        {/*remove the date*/}
                        {/*<Row className={styles.date}>{props.date.slice(8,10)+'.'+props.date.slice(5,7)+'.'+props.date.slice(0,4)}</Row>*/}

                        {/*remove the link from title*/}
                        {/*<Row className={styles.title}><a target='_blank' rel="noopener noreferrer" href={`/aktuelles/blogartikel/${props.id}`} >{props.title}</a></Row>*/}

                        <Row className={styles.title}>{props.title}</Row>
                        <Row className={styles.content}>{props.content}</Row>
                        {props.link === 'pdf' ?
                            <Row>
                                <Button className={styles.button}>
                                    <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={props.pdf} >
                                        {language === 'en' ? 'MORE' : 'MEHR'}
                                    </a>
                                </Button>
                            </Row>
                        : props.link === 'website' ?
                            <Row>
                                <Button className={styles.button}>
                                    <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={props.weblink} >
                                        {language === 'en' ? 'MORE' : 'MEHR'}
                                    </a>
                                </Button>
                            </Row>
                        :
                            <Row>
                                <Button className={styles.button}>
                                    <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={`/aktuelles/blogartikel/${props.id}`} >
                                        {language === 'en' ? 'MORE' : 'MEHR'}
                                    </a>
                                </Button>
                            </Row>
                        }
                    </Col>
                </Row>
            </Container>
            :
            <Container className={`justify-content-center ${styles['blog-preview-container']}`}>
                <Row>
                    <Col lg={4} className={styles.image}>
                        <img alt='' src={props.image} />
                    </Col>
                    <Col lg={8} className={styles.press}>
                        {/*remove the date*/}
                        {/*<Row className={styles.date}>{props.date.slice(8,10)+'.'+props.date.slice(5,7)+'.'+props.date.slice(0,4)}</Row>*/}

                        {/*remove the link from title*/}
                        {/*<Row className={styles.title}><a target='_blank' rel="noopener noreferrer" href={`/aktuelles/blogartikel/${props.id}`} >{props.title}</a></Row>*/}

                        <Row className={styles.title}>{props.title}</Row>
                        <Row className={styles.content}>{props.content}</Row>
                        {props.link === 'pdf' ?
                            <Row>
                                <Button className={styles.button}>
                                    <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={props.pdf} >
                                        {language === 'en' ? 'MORE' : 'MEHR'}
                                    </a>
                                </Button>
                            </Row>
                        : props.link === 'website' ?
                            <Row>
                                <Button className={styles.button}>
                                    <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={props.weblink} >
                                        {language === 'en' ? 'MORE' : 'MEHR'}
                                    </a>
                                </Button>
                            </Row>
                        :
                            <Row>
                                <Button className={styles.button}>
                                    <a style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" href={`/aktuelles/blogartikel/${props.id}`} >
                                        {language === 'en' ? 'MORE' : 'MEHR'}
                                    </a>
                                </Button>
                            </Row>
                        }
                    </Col>
                </Row>
            </Container>
    ))

    function showMoreHandler() {
        setMaxCards (maxCards + 3)
    }

    return (
        <Container>
            <Row>{presses}</Row>
            <Row style={{marginTop: '20px'}}>
                <ButtonCenter
                    position='justify-content-center'
                    value={language === 'en' ? 'MORE' : 'MEHR'}
                    onclick={showMoreHandler}
                />
            </Row>
        </Container>
    )
}

export default PressPreviewBlock;
