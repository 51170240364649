import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import grupe_logo from '../../assets/GRUPE-Logo.svg';
import grupe_claim from '../../assets/GRUPE-claim.svg';
import IVD_Weiterbildungssiegel_2021_4c from '../../assets/IVD_Weiterbildungssiegel_2021_4c.svg';
//import grupe_search from '../../assets/Icon_search.svg';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import useLanguageContext from '../../context/useLanguageContext';
import { headerGerman, headerEnglish } from '../../models/headerModel';
import { Link } from 'react-router-dom';
import Button from "react-bootstrap/Button";

export function Header() {
    const { language, toggleLanguage } = useLanguageContext();
    const [colors, setColors] = useState(false);
    //const [showfield, setShowfiled] = useState(false);
    const [form, setForm] = useState(headerGerman);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (language === 'en') {
            setForm(headerEnglish)
            setColors(true)
        } else {
            setForm(headerGerman)
            setColors(false)
        }
    }, [language]);

    function handleClick() {
        toggleLanguage();
        setColors(!colors)
    }

    return (
        <Navbar bg='white' expand='lg' fixed='top' className='p-0' expanded={expanded}>
            <Container className={styles.container}>
                <Navbar.Brand as={Link} to={'/'} onClick={() => setExpanded(expanded ? false : false)}>
                    <img src={grupe_logo} className={styles.img1} alt='grupe_logo' />
                    <img src={grupe_claim} className={styles.img2} alt='grupe_logo' />
                    {/*<div className={styles.claim}>Fachmakler für Gewerbe- und Anlageimmobilien</div>*/}
                </Navbar.Brand>
                <div className={styles.button}>
                    <Button href='tel:+493089069680'><strong>{form.call}</strong></Button>
                </div>
                <img src={IVD_Weiterbildungssiegel_2021_4c} className={styles.img3} alt='grupe_logo' />
                <Navbar.Toggle aria-controls='basic-navbar-nav' onClick={() => setExpanded(expanded ? false : 'expanded')} />
                <Navbar.Collapse className={styles.navigationBox} id='basic-navbar-nav'>
                    <div className={styles.dropdown} onClick={() => setExpanded(expanded ? false : 'expanded')}>
                        <div className={styles.metaNavigationBox}>
                            <Nav className={`ml-auto align-items-center ${styles.metaNavigation}`}>
                                <Nav.Link as={Link} to={'/ueber-uns'} className={styles.navLink}>{form.aboutUs}</Nav.Link>
                                <Nav.Link as={Link} to={'/unser-engagement'}
                                    className={styles.navLink}>{form.engagement}</Nav.Link>
                                <Nav.Link as={Link} to={'/kontakt'} className={styles.navLink}>{form.contact}</Nav.Link>
                                {/* Suchefeld */}

                                {/*<div>*/}
                                {/*    <input type='image' alt='search' src={grupe_search} width='20' height='20'*/}
                                {/*           onClick={() => setShowfiled(showfield ? false : true)}*/}
                                {/*           className='d-inline-block mr-5 ml-2' />*/}
                                {/*    {showfield && <input type='text' alt='search' placeholder='Suche' name='search' />}*/}
                                {/*</div>*/}

                                {/* Language Settings */}

                                {/*<div className='d-flex'>
                                    <Nav.Link className={colors ? styles.languageUnclicked : styles.languageClicked}
                                              onClick={handleClick}>DE</Nav.Link>
                                    <div className={`d-flex align-items-center ${styles.languageUnclicked}`}>/</div>
                                    <Nav.Link className={!colors ? styles.languageUnclicked : styles.languageClicked}
                                              onClick={handleClick}>EN</Nav.Link>
                                </div>*/}
                            </Nav>
                        </div>
                        <div style={{ width: '100%' }}>
                            <Nav className={`ml-auto ${styles.navigation}`}>
                                <Nav.Link as={Link} to={'/vermietung'} className={styles.link}>{form.rent}</Nav.Link>
                                <hr className={styles.line} />
                                <Nav.Link as={Link} to={'/verkauf'} className={styles.link}>{form.sale}</Nav.Link>
                                <hr className={styles.line} />
                                <Nav.Link as={Link} to={'/aktuelles'} className={styles.link}>{form.news}</Nav.Link>
                                <hr className={styles.line} />
                                <Nav.Link as={Link} to={'/handelsindex'} className={styles.link}>{form.index}</Nav.Link>
                                <hr className={`mb-0 ${styles.line}`} />
                            </Nav>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
