import React, {useState, useEffect } from 'react';
import styles from '../Start/index.module.scss';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import ImageText from '../../components/ImageText';
import TeamMember from '../../components/TeamMember';
import Facts from '../../components/Facts';
import Heading from '../../components/Heading';
import OfferOfDay from '../../components/OfferOfDay';
import useFetchBackend from '../../services/useFetchBackend';
import HeaderTextButton from "../../components/HeaderTextButton";
import Reference from "../../components/Reference";
import useLanguageContext from "../../context/useLanguageContext";

function AboutUs() {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    const [aboutUs, setAboutUs] = useState({});
    const [teamText, setTeamText] = useState( {});
    const [teamMember, setTeamMembers] = useState([]);
    const [facts, setFacts] = useState([]);
    const backendDailyOffers = useFetchBackend('property?action=search&sale=Miete&full=1');
    const [salesObject, setSalesObject] = useState({});
    const [reference, setReference] = useState({});
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress AboutUs page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ?  await fetchContent('/wp-json/acf/v3/pages/1392') : await fetchContent('/wp-json/acf/v3/pages/65');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            setAboutUs({
                content: <div dangerouslySetInnerHTML={{__html: acf.about_us_content}}/>,
                image: acf.about_us_image
            });

            setTeamText({
                header: acf.team_header
            })

            const members = [];
            for (let i = 1; i < 4; i++) {           // Grenze erhöhen, für mehr Mitglieder
                members.push(
                    {
                        image: acf[`team_member_${i}`]['image'],
                        name: acf[`team_member_${i}`]['name'],
                        infos: acf[`team_member_${i}`]['content'],
                        activity: acf[`team_member_${i}`]['activity'],
                        activitydescription: acf[`team_member_${i}`]['aktivitatenbeschreibung'],
                        company: acf[`team_member_${i}`]['contact']['company'],
                        street: acf[`team_member_${i}`]['contact']['street'],
                        city: acf[`team_member_${i}`]['contact']['city'],
                        email: acf[`team_member_${i}`]['e-mail'],
                        phone: acf[`team_member_${i}`]['phone'],
                    }
                )
            };
            setTeamMembers(members);

            setReference({
                name1: acf.references_testimonial.name,
                company1: acf.references_testimonial.company,
                content1: acf.references_testimonial.content,
                image1: acf.references_testimonial.image.url,
                header2: acf.references_header,
                content2: acf.references_content
            })

            setSalesObject({
                header1: acf.selling_object_header,
                content: acf.selling_object_content
            });

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    // fetch Wordpress Facts
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1266') : await fetchContent('/wp-json/acf/v3/pages/938');
            const acf = json.acf;

            const fact = [];
            for(let i = 1; i< 6; i++){
                fact.push({
                    id: i,
                    number: acf[`fact_${i}`]['number'],
                    description: acf[`fact_${i}`]['description']
                })
            }
            setFacts(fact);

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1392') : await fetchContent('/wp-json/wp/v2/pages/65')
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            <div style={{marginTop: '20px', fontSize: '18px'}}>
                <ImageText data={aboutUs} />
            </div>
            <div style={{marginTop: '20px'}}>
                <TeamMember member={teamMember} header={teamText.header} />
            </div>
            <div style={{marginTop: '20px'}}>
                <Heading fontSize={styles.fontSize50} heading={language === 'en' ? 'FACTS & NUMBERS' : 'FAKTEN & ZAHLEN'} />
            </div>
            <div style={{marginBottom: '20px'}}>
                <Facts fact={facts}/>
            </div >
                <Reference  reference={reference} button={language === 'en' ? 'to references' : 'zu Referenzen'} link='/ueber-uns/referenzen' />
            <div style={{marginTop: '20px'}}>
                {backendDailyOffers[0] && <OfferOfDay cards={backendDailyOffers} />}
            </div>
            <div>
                <HeaderTextButton content={salesObject} link='/verkauf/ihr-verkaufsobjekt' button={language === 'en' ? 'MORE' : 'MEHR'}/>
            </div>
        </div>
    )
}

export default AboutUs;
