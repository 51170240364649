import React, {useState, useEffect } from 'react';
import styles from './index.module.scss';
import useApiDomainContext from '../../context/useApiDomainContext'
import Banner from '../../components/Banner';
import TextCenter from '../../components/TextCenter';
import Facts from '../../components/Facts';
import ButtonCenter from '../../components/ButtonCenter';
import OfferOfDay from '../../components/OfferOfDay';
import TestimonialsBlock from '../../components/TestimonialsBlock';
import CardOrder from '../../components/CardOrder';
import Heading from '../../components/Heading';
import PressPreview from '../../components/PressPreview';
import CardImage from '../../components/CardImage';
import NetworkSlider from '../../components/NetworkSlider';
import useFetchBackend from '../../services/useFetchBackend';
import useFetchSuccesses from '../../services/useFetchSuccesses';
import useLanguageContext from '../../context/useLanguageContext';

function Start() {
    const {apiDomain} = useApiDomainContext();
    const [banners, setBanner] = useState([]);
    const [aboutUs, setAboutUs] = useState({});
    const [cityMap, setCityMap] = useState({});
    const [index, setIndex] = useState({});
    const [engagement, setEngagement] = useState({});
    const [press, setPresses] = useState({});
    const rentSuccessesTestimonials = useFetchSuccesses('?categories=6');
    const saleSuccessesTestimonials = useFetchSuccesses('?categories=7');
    const backendDailyOffers = useFetchBackend('property?action=search&sale=Miete&full=1');
    const [network, setNetwork] = useState([]);
    const [facts, setFacts] = useState([]);
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress Start page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1160') : await fetchContent('/wp-json/acf/v3/pages/52');
            const acf = json.acf;

            const banners = [];
            for (let i = 1; i < 6; i++) {
                if (acf[`banner_${i}`]['image']) {
                    banners.push(
                        {
                            id: i,
                            image: acf[`banner_${i}`]['image'],
                            header1: acf[`banner_${i}`]['header_1'],
                            header2: acf[`banner_${i}`]['header_2'],
                            paragraph: acf[`banner_${i}`]['content'],
                            link: acf[`banner_${i}`]['link']
                        }
                    )
                }
            };
            setBanner(banners);

            setAboutUs({
                header: acf.about_us_header,
                content: acf.about_us_content
            });

            setCityMap({
                header1: acf.city_map_header_1,
                header2: acf.city_map_header_2,
                content: acf.city_map_content,
                image: acf.city_map_image
            });

            setIndex({
                header1: acf.index_header_1,
                header2: acf.index_header_2,
                content: acf.index_content,
                image: acf.index_image
            });

            setEngagement({
                header1: acf.engagement_header_1,
                header2: acf.engagement_header_2,
                content: acf.engagement_content,
                image: acf.engagement_image
            });

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    // fetch Wordpress Blog posts
    useEffect(() => {
        async function updateState() {
            const json = await fetchContent('/wp-json/wp/v2/posts?categories=5&_embed');
            const press = json.map(b => ({
                image: b._embedded['wp:featuredmedia']?
                    b._embedded['wp:featuredmedia'][0].source_url : '',
                title: b.title.rendered,
                date: b.date,
                content: <div dangerouslySetInnerHTML={{__html: b.excerpt.rendered}}/>,
                link: b.acf.linkart,
                weblink: b.acf.weblink,
                pdf: b.acf.pdf_download,
                id: b.id
            }))

            setPresses(press);

        }
        updateState().catch(err => console.error(err));
    }, []);

    // fetch Wordpress Network posts
    useEffect(() => {
        async function updateState() {
            const json = await fetchContent('/wp-json/wp/v2/posts?categories=4&_embed');
            const networks = json.map(network => ({
                    image: network._embedded['wp:featuredmedia']?
                        network._embedded['wp:featuredmedia'][0].source_url : '',
                    title: network.title.rendered,
                    content: <div dangerouslySetInnerHTML={{__html: network.excerpt.rendered}}/>,

                })
            )
            setNetwork(networks);

        }
        updateState().catch(err => console.error(err));
    }, []);

    // fetch Wordpress Facts
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1266') : await fetchContent('/wp-json/acf/v3/pages/938');
            const acf = json.acf;

            const fact = [];
            for(let i = 1; i< 6; i++){
                fact.push({
                    id: i,
                    number: acf[`fact_${i}`]['number'],
                    description: acf[`fact_${i}`]['description']
                })
            }
            setFacts(fact);

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    // fetch Wordpress Start page title
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1160') : await fetchContent('/wp-json/wp/v2/pages/52');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <Banner data={banners} />
            <div style={{marginTop: '20px'}}>
                <TextCenter data={aboutUs}/>
            </div>
            <div style={{marginTop: '20px'}}>
                <Facts fact={facts}/>
            </div>
            <div>
                {backendDailyOffers[0] && <OfferOfDay cards={backendDailyOffers} />}
            </div>
            <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <TestimonialsBlock rent={rentSuccessesTestimonials} sale={saleSuccessesTestimonials} />
            </div>
            <div>
                <Heading heading={language === 'en' ? 'NEWS' : 'AKTUELLES'} />
            </div>
            <div style={{marginTop: '20px'}}>
                <PressPreview data={press[0]}/>
            </div>
            <div>
                <ButtonCenter position='justify-content-center' value={language === 'en' ? 'More articles' : 'Mehr Artikel'} link='/aktuelles'/>
            </div>
            <div style={{marginTop: '20px'}}>
                <CardImage content={engagement} link='/unser-engagement'/>
            </div>
            <div style={{marginTop: '20px'}}>
                <Heading heading={language === 'en' ? 'OUR NETWORK' : 'UNSER NETZWERK'} />
            </div>
            <div>
                <NetworkSlider networks={network} />
            </div>
        </div>
    )
}

export default Start;
