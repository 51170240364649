import React, { useEffect, useState } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
import { useParams } from 'react-router-dom';
import Toppic from "../../components/Toppic";
import ObjectDescription from '../../components/ObjectDescription';
import ObjectDetails from '../../components/ObjectDetails';
import ContactForm from '../../components/ContactForm';
import ObjectSlider from '../../components/ObjectSlider';
import useLanguageContext from "../../context/useLanguageContext";

function DetailedView() {
    const {apiDomain} = useApiDomainContext();
    const {id} = useParams();
    const newId = id.split('-');
    const propId = newId.pop();
    const [property, setProperty] = useState({});
    const [firstpic, setFirstPic] = useState([]);
    const [allPics, setAllPics] = useState([]);
    const [title, setTitle] = useState();
    const { language } = useLanguageContext();

    //fetch Backend property
    useEffect(() => {
        async function getData() {
            const newId = id.split('-');
            const propId = newId.pop();

            const response = await fetchContent(`/wp-json/flowdesk/property/${propId}`);
            console.log(response);
            if (response.hideProperty || response.disabled || !response.title) document.location = 'https://www.grupeimmobilien.de';
            //const response = await fetchContent(`/wp-json/flowdesk/property/${id}`);
            setProperty(response);
            response && setTitle(response.type)
            if(response.pic && response.pic[0]) {
                const picIds = response.pic;
                const picArr = await Promise.all(picIds.map(
                    // fetch Backend property pics
                    async pic => await fetchContent(`/wp-json/flowdesk/document/${pic}`)
                ));
                setFirstPic(picArr[0]);
                setAllPics(picArr);
                setTitle(response.type)
            }
        }
        getData()
    }, []);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if(language === 'en') {
        if(title === '2') {
                document.title = 'Office offer'
            }
        else {
                document.title = 'Store offer'
            }
        }
    else {
        if(title === '2') {
            document.title = 'Gewerbeangebot'
        }
        else {
            document.title = 'Ladenangebot'
        }
    }

    return (
        <div>
            <div>
                <Toppic header={language === 'en' ? title === '2' ? 'Office offer' : 'Store offer' : title === '2' ? 'Gewerbeangebot' : 'Ladenangebot'} image={firstpic.file}/>
            </div>
            <div style={{marginTop: '40px'}}>
                <ObjectDescription content={property} />
            </div>
            <div>
                <ObjectSlider pics={allPics} />
            </div>
            <div style={{marginTop: '20px'}}>
                <ObjectDetails content={property} />
            </div>
            <div style={{marginTop: '20px'}}>
                <ContactForm propertyId={propId} />
            </div>

        </div>
    )
}

export default DetailedView;
