import React, { useState } from "react";
import { LanguageContext } from './LanguageContext';

function LanguageContextProvider({children}) {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'de');

    function toggleLanguage() {
        const l = language === 'de' ? 'en' : 'de';
        setLanguage(l);
        localStorage.setItem('language', l);
    }

    return (
        <LanguageContext.Provider value={{language, toggleLanguage}}>
            {children}
        </LanguageContext.Provider>
    );
}

export default LanguageContextProvider;
