import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Logo from '../../assets/GRUPE-Logo.svg';
import Claim from '../../assets/GRUPE-claim.svg';
import { footerGerman, footerEnglish } from '../../models/footerModel';
import useLanguageContext from '../../context/useLanguageContext';

function Footer() {
    const {language} = useLanguageContext();
    const [form, setForm] = useState(footerGerman);

    useEffect(() => {
        if (language === 'en') {
            setForm(footerEnglish)
        } else {
            setForm(footerGerman)
        }
    }, [language]);

    return (
        <div>
            <div className={styles['site-footer']}>
                <Row className='mx-0'>
                    <Container className='d-flex flex-wrap justify-content-center'>
                        <Image src={Logo}
                               className={styles.logo} />
                        <Image src={Claim}
                               className={styles.claim} />
                    </Container>
                </Row>
                <Row className='mt-4 mx-0'>
                    <Container className='d-flex flex-wrap justify-content-center'>
                        <Link to='/' className='ml-3 mr-3'>{form.home}</Link>
                        <Link to='/aktuelles' className='ml-3 mr-3'>{form.news}</Link>
                        <Link to='/ueber-uns' className='ml-3 mr-3'>{form.aboutUs}</Link>
                        <Link to='/unser-engagement' className='ml-3 mr-3'>{form.engagement}</Link>
                        <Link to='/impressum' className='ml-3 mr-3'>{form.impressum}</Link>
                        <Link to='/datenschutz' className='ml-3 mr-3'>{form.privacy}</Link>
                    </Container>
                </Row>
                <Row className='mt-3 mx-0'>
                    <Container className='d-flex flex-wrap justify-content-center align-content-between'>
                        <Button className={styles['footer-buttons']} as={Link} to='/vermietung'><strong>{form.rent.toUpperCase()}</strong></Button>
                        <Button className={styles['footer-buttons']} as={Link} to='/verkauf'><strong>{form.sale.toUpperCase()}</strong></Button>
                        <Button className={styles['footer-buttons']} href='tel:+493089069680'><strong>{form.call.toUpperCase()}</strong></Button>
                        <Button className={styles['footer-buttons']} as={Link} to='/kontakt'><strong>{form.contact.toUpperCase()}</strong></Button>
                    </Container>
                </Row>
            </div>
        </div>
    )
}

export default Footer;
