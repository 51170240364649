import React  from 'react';
import styles from './index.module.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrevArrow from '../../assets/Icon_arrow_left.svg'
import NextArrow from '../../assets/Icon_arrow_right.svg'
import Container from "react-bootstrap/Container";
import Testimonials from "../Testimonials";

function TestimonialSlider({testimonials = {image: '', name: '', company: '', content: ''}}) {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            >
                <img alt='nextArrow' src={NextArrow} />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block"}}
                onClick={onClick}
            >
                <img alt='prevArrow' src={PrevArrow} />
            </div>
        );
    }

    const cards = testimonials.map(card =>
        <div className={styles.cards} key={card.id}>
            <Testimonials key={`${card.id}`}
                          image={card.image}
                          name={`${card.prename} ${card.surname}`}
                          company={card.company}
                          content={card.content}
                          bodyBackground={'rgba(2,103,171, 0.5)'}
            />
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        arrows: true,
        centerMode: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Container className={styles.container}>
            <Slider {...settings}>
                {cards}
            </Slider>
        </Container>
    );
}

export default TestimonialSlider;
