import React from 'react';
import styles from './index.module.scss'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import useLanguageContext from "../../context/useLanguageContext";

function HeaderTextTwoButton({content, link1, link2, download, value, isBtn=true}) {
    const { language } = useLanguageContext();

    return (
        <div className={styles.div}>
            <Container className={styles.container}>
                <Row className={styles.header2}>
                    {content.header}
                </Row>
                <Row>
                    <Col md></Col>
                    <Col md sm={12}>
                        <Row className={styles.content}>
                            <Col>
                                {content.content}
                            </Col>
                        </Row>
                        <Row className={'mt-4 justify-content-center'}>
                            {isBtn &&
                                <Col>
                                    {download === undefined
                                        ? <Button as={Link} to={link1}
                                                  className={styles.button}>{value.toUpperCase()}</Button>
                                        : <Button href={download} target="_blank" rel="noopener noreferrer" download
                                                  className={styles.button}>DOWNLOAD FORMULAR</Button>
                                    }
                                </Col>
                            }
                            <Col>
                                <Button as={Link} to={link2} className={styles.button}>{language === 'en' ? 'CONTACT US' : 'KONTAKTIEREN SIE UNS'}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default HeaderTextTwoButton;
