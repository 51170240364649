import React from 'react';
import styles from './index.module.scss';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Row from "react-bootstrap/Row";
import { Link } from 'react-router-dom';

function CardStandard({image, header, title, content, linkId}) {

    function titleToPath(str) {
        return str
            .toLowerCase()
            .replace(/\u00fc/g, 'ue')
            .replace(/\u00e4/g, 'ae')
            .replace(/\u00f6/g, 'oe')
            .replace(/\u00df/g, 'ss')
            .replace(/ /g, '-')
            .replace(/[^a-z-]/g, '');
    }

    return (
        <Card className={styles['card-component']} as={Link}
            to={`/${titleToPath(title === 'Angebot Bürofläche' ? 'büroangebote' : 'ladenangebote')}-${titleToPath(content)}-${linkId}`} >
            <Card.Img className={styles['card-image']} src={image} />
            <Card.ImgOverlay>
                <Row className='justify-content-center'>
                    <Badge className={styles['card-badge']}>
                        {header}
                    </Badge>
                </Row>
            </Card.ImgOverlay>
            <Card.Body className={styles['card-body']}>
                <Card.Text className={styles['card-title']}>
                    {title}
                </Card.Text>
                <Card.Text className={styles['card-text']}>
                    {content}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default CardStandard;
