export const contactFormGerman = {
    salutationLabel: 'Anrede',
    salutation: ['Frau', 'Herr'],
    company: 'Firma',
    firstname: 'Vorname *',
    lastname: 'Nachname *',
    mail: 'E-mail *',
    street: 'Straße, Hausnummer *',
    industry: 'Branche *',
    postCode: 'PLZ *',
    city: 'Ort *',
    phone: 'Telefon *',
    fax: 'Fax',
    send: 'Absenden',
    errorSubmitting: 'Es ist ein Fehler aufgetreten. Bitte versuchen is es erneut.',
    successSubmitting: 'Ihre Anfrage wurde erfolgreich verschickt.',
    wish: 'Wünsche',
    wishes: ['mehr Informationen', 'Besichtigungstermin', 'Rückruf'],
    requiredField: '* Diese Felder müssen ausgefüllt werden.'
};


export const contactFormEnglish = {
    salutationLabel: 'Salutauion',
    salutation: ['Mrs.', 'Mr.'],
    company: 'Company',
    firstname: 'First name *',
    lastname: 'Last name *',
    mail: 'E-mail *',
    street: 'Street, House number *',
    industry: 'Industry *',
    postCode: 'Post code *',
    city: 'City *',
    phone: 'Phone *',
    fax: 'Fax',
    send: 'send',
    errorSubmitting: 'An error has occurred. Please try it again.',
    successSubmitting: 'Your request was sent successfully.',
    wish: 'wishes',
    wishes: ['Further information', 'Viewing appointment', 'Call back'],
    requiredField: '* These fields must be filled in.'
};
