import React, { useEffect, useState } from 'react';
//import { useParams } from 'react-router-dom';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from "../../components/Toppic";
//import ImageText from "../../components/ImageText";
import TestimonialSlider from "../../components/TestimonialSlider";
import useFetchSuccesses from "../../services/useFetchSuccesses";
import SuccessExamples from "../../components/SuccessExamples";
import OfferOfDay from "../../components/OfferOfDay";
import useFetchBackend from "../../services/useFetchBackend";
import Heading from '../../components/Heading';
import useLanguageContext from "../../context/useLanguageContext";

function RentSuccesses() {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    //const [successes, setSuccesses] = useState({});
    const rentSuccessesTestimonials = useFetchSuccesses('?categories=6');
    const rentSuccessesCards = useFetchSuccesses('?categories=10');
    {/* to fetch data of Vermietungserfolge from Backend */}
    //const rentSuccessesCards = useFetchBackend('property?action=search&success=1&full=1');
    const backendDailyOffers = useFetchBackend('property?action=search&sale=Miete&full=1');
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress rental successes page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1566') : await fetchContent('/wp-json/acf/v3/pages/105')
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            /*setSuccesses({
                content: acf.successes_content,
                image: acf.successes_image
            });*/
        }
        updateState()
    }, [language]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1566') : await fetchContent('/wp-json/wp/v2/pages/105');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <div>
                <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            </div>
            <div className='justify-content-center' style={{marginTop: '40px'}}>
                <Heading fontSize='3em' heading={language === 'en' ? 'RENTAL SUCCESSES' : 'VERMIETUNGSERFOLGE'} />
            </div>
            <div>
                <TestimonialSlider testimonials={rentSuccessesTestimonials} />
            </div>
            {/*<div style={{marginTop: '120px'}}>
                <ImageText data={successes}/>
            </div>*/}
            <div style={{marginTop: '0px'}}>
                <SuccessExamples content={rentSuccessesCards} />
            </div>
            <div>
                {backendDailyOffers[0] && <OfferOfDay cards={backendDailyOffers} />}
            </div>
        </div>
    )

}

export default RentSuccesses;
