import React, { useEffect, useState } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import EngagementCorner from '../../components/EngagementCorner';
import EngagementText from '../../components/EngagementText';
import useLanguageContext from "../../context/useLanguageContext";

function Engagement() {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    const [engagements, setEngagement] = useState([]);
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress Engagement page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1395') : await fetchContent('/wp-json/acf/v3/pages/66');
            const acf = await json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            const len = Object.keys(acf).length - 2;
            const engagement = [];
            for (let i = 1; i < len; i++) {
                engagement.push(
                    {
                        header: acf[`engagement_${i}`]['header'],
                        p1: acf[`engagement_${i}`]['content_1'],
                        p2: acf[`engagement_${i}`]['content_2'],
                        image: acf[`engagement_${i}`]['image'],
                        image_1: acf[`engagement_${i}`]['image_1'] ? acf[`engagement_${i}`]['image_1'] : undefined,
                        image_2: acf[`engagement_${i}`]['image_2'] ? acf[`engagement_${i}`]['image_2'] : undefined,
                        image_3: acf[`engagement_${i}`]['image_3'] ? acf[`engagement_${i}`]['image_3'] : undefined,
                        image_4: acf[`engagement_${i}`]['image_4'] ? acf[`engagement_${i}`]['image_4'] : undefined,
                    }
                )
            };
            setEngagement(engagement);
        }
        updateState().catch(err => console.error(err));
    }, [language]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1395') : await fetchContent('/wp-json/wp/v2/pages/66');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            <EngagementCorner content={engagements[0]} />
            <EngagementText content={engagements[1]} />
            <EngagementCorner content={engagements[2]} />
            <EngagementText content={engagements[3]} />
            <EngagementCorner content={engagements[4]} />
            <EngagementText content={engagements[5]} />
        </div>
    )
}

export default Engagement;
