import React, { useState } from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonCenter from '../ButtonCenter';
import SuccessCard from '../SuccessCard';
import useLanguageContext from '../../context/useLanguageContext';

function SuccessExamples({content}) {
    const {language} = useLanguageContext();
    const [maxCards, setMaxCards] = useState(6);

    const cards = content.slice(0, maxCards).map(prop => (
        <Col xs='12' sm='6' lg='4' className='mt-4' key={`${prop.id}`}>
            <SuccessCard image={prop.property_image}
                         title={`${prop.street}`}
                         name={prop.property_company}
                         content={prop.statement}
            />
        </Col>
    ))

    function showMoreHandler() {
        setMaxCards(maxCards + 3)
    }

    return (
        <Container className={styles.container}>
            <Row className={styles.cards}>
                {cards}
            </Row>
            <Row className={styles.btn}>
                {maxCards < content.length &&
                <ButtonCenter
                    position='justify-content-center'
                    value={language === 'en' ? 'MORE' : 'MEHR'}
                    onclick={showMoreHandler}
                />
                }
            </Row>
        </Container>
    )
}

export default SuccessExamples;
