import React, {useState, useEffect } from 'react';
import useLanguageContext from '../../context/useLanguageContext';
import useApiDomainContext from '../../context/useApiDomainContext';
import { Container } from 'react-bootstrap';

function PrivacyAndImprint({page}) {
    const {language} = useLanguageContext();
    const {apiDomain} = useApiDomainContext();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState({});

    useEffect(() => {
        async function updateState() {
            const response = await fetch(`${apiDomain}/wp-json/wp/v2/pages/${page}`);
            const json = await response.json();
            setTitle(json.title.rendered);
            setContent(json.content.rendered);
        }
        updateState().catch(err => console.error(err));;
    },[language]);

    if(title) {document.title = title}

    return (
        <Container style={{marginTop: '40px', fontSize: '18px'}} className='d-flex justify-content-center'>
            <div dangerouslySetInnerHTML={{__html: content}}/>
        </Container>
    )
}

export default PrivacyAndImprint;
