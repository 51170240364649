import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import styles from './index.module.scss';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import useLanguageContext from "../../context/useLanguageContext";

function Switch({content, link1, link2, onClick1, onClick2}) {
    const { language } = useLanguageContext();
    return (
        <Container className={styles.container}>
            <Card
                style={{backgroundImage: `url(${content.image})`,
                        backgroundSize: "100% 100%"}}
                className={styles.layer1}>
                <Card.Body className={styles.layer2}>
                    <Card.Title className={styles.header}>{content.header1}</Card.Title>
                    {/*<Card.Title className={styles.header2}>{content.header2}</Card.Title>*/}
                    <Row className={'m-auto justify-content-center'}>
                        <Button as={Link} to={link1} onClick={onClick1} className={styles.button}>{language === 'en' ? 'STORE OFFERS' : 'LADENANGEBOTE'}</Button>
                        <Button as={Link} to={link2} onClick={onClick2} className={styles.button}>{language === 'en' ? 'OFFICE OFFERS' : 'GEWERBEANGEBOTE'}</Button>
                    </Row>
                    <br/>
                    <Card.Title className={styles.header}>{content.header2}</Card.Title>
                    <Row className={'m-auto justify-content-center'}>
                        <Button as={Link} to={'/vermietung/ihr-vermietungsobjekt'} className={styles.button}>{language === 'en' ? 'RENTAL OBJECT' : 'VERMIETUNGSOBJEKT'}</Button>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Switch;
