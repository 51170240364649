import React, {useState, useEffect } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import TextCenter from "../../components/TextCenter";
import HeaderTextTwoButton from "../../components/HeaderTextTwoButton";
import PropertyForm from "../../components/PropertyForm";
import OfferOfDay from "../../components/OfferOfDay";
import useFetchBackend from "../../services/useFetchBackend";
import useLanguageContext from "../../context/useLanguageContext";

function YourRentalObject() {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    const [introduction, setIntroduction] = useState({});
    const [consulting, setConsulting] = useState({});
    const [privacy, setPrivacy] = useState({});
    const backendDailyOffers = useFetchBackend('property?action=search&sale=Miete&full=1');
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress YourRentalObject page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1626') : await fetchContent('/wp-json/acf/v3/pages/245');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            setIntroduction({
                header: acf.introduction_header,
                content: acf.introduction_content
            })

            setConsulting({
                header: acf.consulting_header,
                content: acf.consulting_content
            });

            setPrivacy({
                pdf: acf.privacy_pdf
            })
        }
        updateState().catch(err => console.error(err));
    }, [language]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1626') : await fetchContent('/wp-json/wp/v2/pages/245');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            <div style={{marginTop: '20px'}}>
                <TextCenter data={introduction} />
            </div>
            <div style={{marginTop: '20px'}}>
                <PropertyForm pdf={privacy.pdf} />
            </div>
           {/* <div>
                <HeaderTextTwoButton content={consulting} value={language === 'en' ? 'to store requests' : 'zu den Ladengeuchen'} link1={'/vermietung/ladengesuche'} link2={'/kontakt'} />
            </div>
           */}
            <div>
                {backendDailyOffers[0] && <OfferOfDay cards={backendDailyOffers} />}
            </div>
        </div>
    )
}

export default YourRentalObject;
