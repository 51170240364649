import React from 'react';
import styles from './index.module.scss';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ForwardArrow from '../../assets/Icon_arrow_forward.svg';
import BackArrow from '../../assets/Icon_arrow_back.svg';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import useLanguageContext from "../../context/useLanguageContext";
import { HashLink as Link } from 'react-router-hash-link';
import {Button} from "react-bootstrap";

export function Banner({data}) {
    const { language } = useLanguageContext();

    const items = data.map(item =>
        <Carousel.Item key={item.id}  data-interval={500} interval={2000} className={styles.carousel}>
            <img alt='' className={styles.layer1} src={item.image} />
            <Card.Body className={styles.layer2}>
                <Card.Title className={styles.header1}>{item.header1}</Card.Title>
                <Card.Title className={styles.header2}>{item.header2}</Card.Title>
                <Card.Text className={styles.paragraph}>{item.paragraph}
                </Card.Text>
                <Button className={styles.button}>
                    <Link to={`/${item.link}`} >{language === 'en' ? 'MORE' : 'MEHR'}</Link>
                </Button>
            </Card.Body>
            {/*die Preile in der rechten Ecke*/}
            <Row className={styles.arrows}>
                <Col className={styles.arrow}>
                    <img src={BackArrow} alt='back' />
                </Col>
                <div className={styles.line}>
                </div>
                <Col className={styles.arrow}>
                    <img src={ForwardArrow} alt='forward' />
                </Col>
            </Row>
        </Carousel.Item>
    );

    return (
        <Container className={styles.container} fluid>
            <Carousel indicators={false}
                      controls={true}
                      touch={true}>
                {items}
            </Carousel>
        </Container>
    )
}

export default Banner;
