import React, { useEffect, useState } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import HeaderTextTwoButton from '../../components/HeaderTextTwoButton';
import OfferOfDay from '../../components/OfferOfDay';
import useFetchBackend from '../../services/useFetchBackend';
import useLanguageContext from "../../context/useLanguageContext";

function StoreRequest() {
    const {apiDomain} = useApiDomainContext()
    const [toppic, setToppic] = useState({});
    const [searchForm, setSearchForm] = useState({});
    const backendDailyOffers = useFetchBackend('property?action=search&sale=Miete&full=1');
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress StoreRequest page
    useEffect(() => {
        async function getData() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1623') : await fetchContent('/wp-json/acf/v3/pages/103');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            })

            setSearchForm({
                header: acf.search_form_header,
                content: acf.search_form_content,
                formular: acf.pdf_formular
            })
        }
        getData()
    }, [language])

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1623') : await fetchContent('/wp-json/wp/v2/pages/103');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return(
        <div>
            <div>
                <Toppic header={toppic.header} image={toppic.image} content={toppic.content} />
            </div>
            <div style={{marginTop: '20px'}}>
                <HeaderTextTwoButton isBtn={false} content={searchForm} link2={'/kontakt'} />
            </div>
            <div>
                {backendDailyOffers[0] &&<OfferOfDay cards={backendDailyOffers} />}
            </div>
        </div>
    )

}

export default StoreRequest;
