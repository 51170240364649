export const formValMsgGerman = {
    required: "Dies ist ein Pflichtfeld.",
    minLength2: "Der Wert muss mindestens zwei Zeichen haben.",
    minLength6: "Der Wert muss mindestens sechs Zeichen haben.",
    minLength8: "Der Wert muss mindestens acht Zeichen lang sein.",
    maxLength4: "Sie haben die die Maximallänge von 4 Zeichen überschritten.",
    maxLength100: "Sie haben die die Maximallänge von 100 Zeichen überschritten.",
    maxLength200: "Sie haben die die Maximallänge von 200 Zeichen überschritten.",
    maxLength300: "Sie haben die die Maximallänge von 300 Zeichen überschritten.",
    maxLength1000: "Sie haben die die Maximallänge von 1000 Zeichen überschritten.",
    postalCode: "Bitte geben Sie eine gültige Postleitzahl ein.",
    email: "Dies ist keine gültige E-Mail Adresse.",
    password: "Die Passwörter stimmen nicht überein.",
    logInError: 'Login fehlgeschlagen',
    logInNetworkError: 'Login fehlgeschlagen. Bitte versuchen Sie es erneut.',
    passwordResetError: 'Zurücksetzen des Passworts fehlgeschlagen',
    passwordResetNetworkError: 'Zurücksetzen des Passworts. Bitte versuchen Sie es erneut.'
};

export const formValMsgEnglish = {
    required: "This is a mandatory field.",
    minLength2: "The value must have at least two characters.",
    minLength8: "The value must be at least eight characters long.",
    maxLength100: "You have exceeded the maximum length of 100 characters.",
    maxLength200: "You have exceeded the maximum length of 200 characters.",
    maxLength300: "You have exceeded the maximum length of 300 characters.",
    postalCode: 'Please enter a valid postcode.',
    email: "This is not a valid e-mail address.",
    password: "The passwords do not match.",
    logInError: 'Login failed',
    logInNetworkError: 'Login failed. Please try again.',
    passwordResetError: 'Failed to reset the password',
    passwordResetNetworkError: 'Reset the password. Please try again.'
};
