import React from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import modifyTextForLinks from '../../services/modifyTextForLinks';
import Debug from '../Debug';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

function EngagementText({content = {header: '', p1: '', p2: '', image: ''}}) {

    const paragraph2 = modifyTextForLinks(content.p2);

    return (
        <div className={styles.background}>
            <Container className={styles.container}>
                <Card
                    style={{
                        backgroundImage: `url(${content.image})`,
                        backgroundSize: '100% 100%'
                    }}
                    className={styles.layer1}>
                    <Card.Body className={styles.layer2}>
                        <Card.Title className={styles.header1}>{content.header}</Card.Title>
                        <Card.Text className={styles.paragraph}>{content.p1}
                        </Card.Text>
                        <Card.Text className={styles.paragraph}>{paragraph2}
                        </Card.Text>
                        {content.image_1 &&
                        <div>
                            <Row>
                                <Col>
                                    <Image className={styles.image} src={content.image_1} />
                                </Col>
                                <Col>
                                    <Image className={styles.image} src={content.image_2} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Image className={styles.image} src={content.image_3} />
                                </Col>
                                <Col>
                                    <Image className={styles.image} src={content.image_4} />
                                </Col>
                            </Row>
                        </div>
                        }

                    </Card.Body>
                </Card>
            </Container>
        </div>

    )
}

export default EngagementText;
