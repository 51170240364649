import React from 'react';
import styles from './index.module.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Footer from '../Footer';
import Header from '../Header';
import Start from '../../pages/Start';
import Rent from '../../pages/Rent';
import Sale from '../../pages/Sale';
import AboutUs from '../../pages/AboutUs';
import Engagement from '../../pages/Engagement';
import Contact from '../../pages/Contact';
import News from '../../pages/News';
import StoreRequest from '../../pages/StoreRequest';
import SaleRequest from '../../pages/SaleRequest';
import RentSuccesses from '../../pages/RentSuccesses';
import SaleSuccesses from '../../pages/SaleSuccesses';
import BlogArtikel from '../../pages/BlogArtikel';
import YourRentalObject from '../../pages/YourRentalObject';
import YourSalesObject from '../../pages/YourSalesObject';
import DetailedView from '../../pages/DetailedView';
import ScrollToTop from '../../components/ScrollToTop'
import CityMap from '../../pages/CityMap';
import References from '../../pages/References';
import CookieConfiramtion from '../CookieConfirmation';
import PrivacyAndImprint from '../../pages/PrivacyPolicy';

function Main({setConsent}) {
    return (
        <>
            <Router>
                <ScrollToTop />
                <div className={styles['page-container']}>
                    <Header />
                    <Container className={styles['main-wrapper']}>
                        <Switch>
                            <Route exact path='/' key='start' component={Start} />
                            <Route exact path='/datenschutz' key='datenschutz' render={(props) => <PrivacyAndImprint page={1158} />}/>
                            <Route exact path='/impressum' key='imprint' render={(props) => <PrivacyAndImprint page={1787} />}/>
                            <Route exact path='/vermietung' key='vermietung' component={Rent} />
                            <Route exact path='/verkauf' key='verkauf' component={Sale} />
                            <Route exact path='/ueber-uns' key='ueber-uns' component={AboutUs} />
                            <Route exact path='/unser-engagement' key='unser-engagement' component={Engagement} />
                            <Route exact path='/kontakt' key='kontakt' component={Contact} />
                            <Route exact path='/aktuelles' key='news' component={News} />
                            <Route exact path='/vermietung/ladengesuche' key='ladengesuche' component={StoreRequest} />
                            <Route exact path='/vermietung/ihr-vermietungsobjekt' key='ihr-vermietungsobjekt' component={YourRentalObject} />
                            <Route exact path='/vermietung/vermietungserfolge' key='vermietungserfolge' component={RentSuccesses} />
                            {/*<Route exact path='/vermietung/detailansicht-vermietungsobjekt/:id' key='detailansicht-vermietungsobjekt' component={DetailedView} />*/}
                            <Route exact path='/:id' key='detailansicht-vermietungsobjekt' component={DetailedView} />
                            <Route exact path='/verkauf/kaufgesuche' key='kaufgesuche' component={SaleRequest} />
                            <Route exact path='/verkauf/ihr-verkaufsobjekt' key='verkaufsobjekt' component={YourSalesObject} />
                            <Route exact path='/verkauf/verkaufserfolge' key='verkaufserfolge' component={SaleSuccesses} />
                            <Route exact path='/aktuelles/blogartikel/:id' key='blogartikel' component={BlogArtikel} />
                            <Route exact path='/handelsindex/handelsstadtplan' key='handelsstadtplan' component={CityMap} />
                            <Route exact path='/ueber-uns/referenzen' key='referenzen' component={References} />
                        </Switch>
                    </Container>
                </div>
                <Footer />
                <CookieConfiramtion setConsent={setConsent}/>
            </Router>
        </>
    );
}

export default Main;
