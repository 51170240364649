import React, { useEffect, useState } from 'react';
import useApiDomainContext from '../../context/useApiDomainContext';
import Toppic from '../../components/Toppic';
//import Container from 'react-bootstrap/Container';
import ImageText from '../../components/ImageText';
import useLanguageContext from "../../context/useLanguageContext";

function CityMap() {
    const {apiDomain} = useApiDomainContext()
    const [toppic, setToppic] = useState({});
    const [infoHandelsindex, setInfoHandelsindex] = useState( {});
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress Handelsstadtplan page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1467') : await fetchContent('/wp-json/acf/v3/pages/94');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            setInfoHandelsindex({
                content: acf.informations_content,
                image: acf.informations_image
            });
        }
        updateState().catch(err => console.error(err));
    }, [language]);

    // fetch Wordpress Start page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1467') : await fetchContent('/wp-json/wp/v2/pages/94');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return(
        <div>
            {/*<Toppic image={toppic.image} header={toppic.header} content={toppic.content} />*/}
            {/*<div style={{marginTop: '40px', marginBottom: '40px'}}>*/}
            {/*    <ImageText data={infoHandelsindex} />*/}
            {/*</div>*/}
            <iframe frameBorder='0' style={{width: '100%', height: '800px'}}
                    src='https://www.grupeimmobilien.de/grupeimmobilien.de/handelsstadtplan'>
            </iframe>
        </div>
    )
}

export default CityMap;
