import React, {useState, useEffect } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import ContactInfo from '../../components/ContactInfo';
import TextCenter from "../../components/TextCenter";
import SubContactForm from '../../components/SubContactForm'
import useLanguageContext from "../../context/useLanguageContext";

function Contact () {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    const [contact, setContact] = useState({});
    const [approach, setApproach] = useState({});
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress Contact page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1438') : await fetchContent('/wp-json/acf/v3/pages/67');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            setContact({
                address: acf.address,
                officeTime: acf.office_hours,
                email: acf.contact.email,
                phone: acf.contact.phone,
                ceo: acf.contact.ceo
            })

            setApproach({
                header: acf.approach_header,
                content: acf.approach_content
            })

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1438') : await fetchContent('/wp-json/wp/v2/pages/67');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return(
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            <div style={{marginTop: '40px', marginBottom: '40px'}}>
                <ContactInfo contact={contact}/>
            </div>
            <div style={{marginBottom: '40px'}}>
                <SubContactForm />
            </div>
            <div className='justify-content-center' style={{marginBottom: '40px'}}>
                <iframe title='Grupe' width="100%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                        src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Durlacher%20Str.%2011A%20Berlin+(Lage%20Grupe)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                </iframe>
            </div>
            <div style={{marginBottom: '40px'}}>
                <TextCenter data={approach}/>
            </div>
        </div>
    )
}

export default Contact;
