import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import Heading from '../Heading';
import useLanguageContext from '../../context/useLanguageContext';
import {
    propFormGerman,
    propFormEnglish,
} from '../../models/propertyFormModel';
import axios from 'axios';
import useApiDomainContext from '../../context/useApiDomainContext';
import Button from 'react-bootstrap/Button';
import {
    formValMsgGerman,
    formValMsgEnglish,
} from '../../models/formValidationModel';
import SpinnerComponent from '../SpinnerComponent';
import {Link} from "react-router-dom";

function SalesForm({ pdf }) {
    const { language } = useLanguageContext();
    const { register, handleSubmit, errors } = useForm({
        mode: 'onChange',
    });
    const [form, setForm] = useState(propFormGerman);
    const { apiDomain } = useApiDomainContext();
    const [formVal, setFormVal] = useState(formValMsgGerman);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const formRef = useRef(null);
    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (language === 'en') {
            setForm(propFormEnglish);
            setFormVal(formValMsgEnglish);
        } else {
            setForm(propFormGerman);
            setFormVal(formValMsgGerman);
        }
    }, [language]);

    const handleReset = () => {
        formRef.current.reset();
        recaptchaRef.current.reset();
        setShowSpinner(false);
    };

    function createCaptcha(value) {
        setDisableButton(false);
    }

    const onSubmit = (data) => {
        //console.log('DATA', data);
        setIsError(false);
        setDisableButton(true);
        setShowSpinner(true);
        setIsSuccess(false);

        let gender = '';
        if (data.salutation === form.salutation[1]) {
            gender = 'male';
        } else if (data.salutation === form.salutation[0]) {
            gender = 'female';
        }

        let weAre = '';
        if (data.weAre === form.owner) {
            weAre = 'owner';
        } else if (data.weAre === form.management) {
            weAre = 'propertyManager';
        } else if (data.weAre === form.pretenant) {
            weAre = 'previousTenant';
        }

        const bodyFormData = new FormData();
        bodyFormData.set('type', 'salesProperty');

        bodyFormData.set('propertyLocation', data.city);
        bodyFormData.set('propertyStreet', data.street);
        bodyFormData.set('construction', data.construction);
        bodyFormData.set('heatingType', data.typeOfHeating);
        bodyFormData.set('propertySize', data.propertySize);
        bodyFormData.set('totalRentalSpace', data.totalRentalSpace);
        bodyFormData.set('commercialArea', data.commercialArea);
        bodyFormData.set('livingSpace', data.livingSpace);
        bodyFormData.set('yearlyRentalIncomeNet', data.yearlyRentalIncomeNet);
        bodyFormData.set('yearlyRentalIncomeGross', data.yearlyRentalIncomeGross);
        bodyFormData.set('presentationOfPrices', data.presentationOfPrices);
        bodyFormData.set('info', data.propertyDescription);

        bodyFormData.set('salut', gender);
        bodyFormData.set('company', data.company);
        bodyFormData.set('prename', data.firstname);
        bodyFormData.set('surname', data.lastname);
        bodyFormData.set('street', data.contactStreet);
        bodyFormData.set('location', data.contactCity);
        bodyFormData.set('zip', data.postCode);
        bodyFormData.set('mail', data.mail);
        bodyFormData.set(`phone[0][label]`, 'Fax');
        bodyFormData.set(`phone[0][value]`, data.fax);
        bodyFormData.set(`phone[1][label]`, 'Arbeit');
        bodyFormData.set(`phone[1][value]`, data.phone);

        axios
            .post(`${apiDomain}/wp-json/flowdesk/customer`, bodyFormData)
            .then((response) => {
                //console.log('RESPONSE FROM BACKEND', response);
                setDisableButton(false);

                if (response.data.id) {
                    setIsError(false);
                    handleReset();
                    setIsSuccess(true);
                } else {
                    setIsError(true);
                    setIsSuccess(false);
                    setShowSpinner(false);
                }
            })
            .catch((error) => {
                console.error('contact form error', error);
                setDisableButton(false);
                setIsError(true);
                setIsSuccess(false);
                setShowSpinner(false);
            });
    };

    return (
        <Container className={styles.container}>
            <Form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.form}
                id='propertyForm'
                ref={formRef}
            >
                <Row className={styles.heading}>
                    <Heading
                        className={styles.heading}
                        heading={form.salesHeading}
                        fontSize={60}
                    />
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='city'>
                            <Form.Label>{form.city}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='city'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.city && (
                                <div className={styles.error}>{errors.city.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='street'>
                            <Form.Label>{form.street}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='street'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.street && (
                                <div className={styles.error}>{errors.street.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='construction'>
                            <Form.Label>{form.construction}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='number'
                                name='construction'
                                ref={register({
                                    maxLength: {
                                        value: 4,
                                        message: formVal.maxLength4,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.construction && (
                                <div className={styles.error}>{errors.construction.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='typeOfHeating'>
                            <Form.Label>{form.typeOfHeating}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='typeOfHeating'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.typeOfHeating && (
                                <div className={styles.error}>
                                    {errors.typeOfHeating.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='propertySize'>
                            <Form.Label>{form.propertySize}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='number'
                                name='propertySize'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.propertySize && (
                                <div className={styles.error}>
                                    {errors.propertySize.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='totalRentalSpace'>
                            <Form.Label>{form.totalRentalSpace}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='number'
                                name='totalRentalSpace'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />

                            {errors.totalRentalSpace && (
                                <div className={styles.error}>{errors.totalRentalSpace.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='commercialArea'>
                            <Form.Label>{form.commercialArea}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='number'
                                name='commercialArea'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.commercialArea && (
                                <div className={styles.error}>{errors.commercialArea.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='livingSpace'>
                            <Form.Label>{form.livingSpace}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='number'
                                name='livingSpace'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.livingSpace && (
                                <div className={styles.error}>
                                    {errors.livingSpace.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='yearlyRentalIncomeNet'>
                            <Form.Label>{form.yearlyRentalIncomeNet}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='number'
                                name='yearlyRentalIncomeNet'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.yearlyRentalIncomeNet && (
                                <div className={styles.error}>
                                    {errors.yearlyRentalIncomeNet.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='yearlyRentalIncomeGross'>
                            <Form.Label>{form.yearlyRentalIncomeGross}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='number'
                                name='yearlyRentalIncomeGross'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.yearlyRentalIncomeGross && (
                                <div className={styles.error}>{errors.yearlyRentalIncomeGross.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='presentationOfPrices'>
                            <Form.Label>{form.presentationOfPrices}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='number'
                                name='presentationOfPrices'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.presentationOfPrices && (
                                <div className={styles.error}>
                                    {errors.presentationOfPrices.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={8}>
                        <Form.Group controlId='propertyDescription'>
                            <Form.Label>{form.propertyDescription}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='textarea'
                                type='text'
                                rows='3'
                                name='propertyDescription'
                                ref={register({
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.propertyDescription && (
                                <div className={styles.error}>
                                    {errors.propertyDescription.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                {/** Pflichtfelder text **/}
                {/*<Row className={`justify-content-md-center`} style={{margin: '1rem'}}>*/}
                {/*    <Col md={8}>*/}
                {/*        <p>{form.requiredField}</p>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row className={styles.heading} style={{ marginTop: '90px' }}>
                    <Heading
                        className={styles.heading}
                        heading={form.heading2}
                        fontSize={60}
                    />
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='salutation'>
                            <Form.Label>{form.salutationLabel}</Form.Label>
                            <Form.Control
                                className={styles.select}
                                as='select'
                                name='salutation'
                                ref={register({ required: true })}
                            >
                                {form.salutation.map((salutation) => (
                                    <option>{salutation}</option>
                                ))}
                            </Form.Control>
                            {errors.salutation && (
                                <div className={styles.error}>{errors.salutation.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='company'>
                            <Form.Label>{form.company}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='company'
                                ref={register({
                                    maxLength: {
                                        value: 300,
                                        message: formVal.maxLength300,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.company && (
                                <div className={styles.error}>{errors.company.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='firstname'>
                            <Form.Label>{form.firstname}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='firstname'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: formVal.required,
                                    },
                                    maxLength: {
                                        value: 300,
                                        message: formVal.maxLength300,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.firstname && (
                                <div className={styles.error}>{errors.firstname.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='lastname'>
                            <Form.Label>{form.lastname}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='lastname'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: formVal.required,
                                    },
                                    maxLength: {
                                        value: 300,
                                        message: formVal.maxLength300,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.lastname && (
                                <div className={styles.error}>{errors.lastname.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='contactStreet'>
                            <Form.Label>{form.contactStreet}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='contactStreet'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: formVal.required,
                                    },
                                    maxLength: {
                                        value: 300,
                                        message: formVal.maxLength300,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.contactStreet && (
                                <div className={styles.error}>
                                    {errors.contactStreet.message}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='postCode'>
                            <Form.Label>{form.postCode}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='postCode'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: formVal.required,
                                    },
                                    maxLength: {
                                        value: 5,
                                        message: formVal.postalCode,
                                    },
                                    minLength: {
                                        value: 5,
                                        message: formVal.postalCode,
                                    },
                                })}
                            />
                            {errors.postCode && (
                                <div className={styles.error}>{errors.postCode.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='contactCity'>
                            <Form.Label>{form.contactCity}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='contactCity'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: formVal.required,
                                    },
                                    maxLength: {
                                        value: 300,
                                        message: formVal.maxLength300,
                                    },
                                    minLength: {
                                        value: 2,
                                        message: formVal.minLength2,
                                    },
                                })}
                            />
                            {errors.contactCity && (
                                <div className={styles.error}>{errors.contactCity.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='phone'>
                            <Form.Label>{form.phone}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='phone'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: formVal.required,
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    minLength: {
                                        value: 8,
                                        message: formVal.minLength8,
                                    },
                                })}
                            />
                            {errors.phone && (
                                <div className={styles.error}>{errors.phone.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={4}>
                        <Form.Group controlId='fax'>
                            <Form.Label>{form.fax}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='text'
                                name='fax'
                                ref={register}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId='mail'>
                            <Form.Label>{form.mail}</Form.Label>
                            <Form.Control
                                className={styles.field}
                                as='input'
                                type='email'
                                name='mail'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: formVal.required,
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: formVal.maxLength100,
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: formVal.email,
                                    },
                                })}
                            />
                            {errors.mail && (
                                <div className={styles.error}>{errors.mail.message}</div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center`} style={{margin: '1rem'}}>
                    <Col md={8}>
                        <p>{form.requiredField}</p>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.paragraph1}`}>
                    <Col md={8}>
                        <p>
                            {form.privacy}
                        </p>
                    </Col>
                </Row>
                <Row className={`justify-content-md-center ${styles.formgroup}`}>
                    <Col md={8}>
                        <Form.Group className={styles.checkbox}>
                            <Form.Check
                                custom
                                className='mb-2'
                                type='checkbox'
                                id='custom4'
                                name='consent'
                                ref={register({ required: true })}
                                label={<label>{form.privacyCommit1}<Link to='/datenschutz'>{form.privacyLink}</Link>{form.privacyCommit2}</label>}
                            />
                            {/* <Form.Check custom className="mb-2" type="checkbox" id="custom5" label="Ich bin kein Roboter (Captcha)"
                                        ref={register({required: true})} /> */}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <ReCAPTCHA
                        hl={language === 'en' ? 'en' : 'de'}
                        sitekey='6Lf_7aIZAAAAAEwc2Y1VRcJXwag6R6c4wmclzhXo'
                        onChange={createCaptcha}
                        ref={recaptchaRef}
                    />
                </Row>
                <Row className='justify-content-center'>
                    <Button
                        type='submit'
                        className={styles.button}
                        disabled={disableButton}
                    >
                        {form.send}
                    </Button>
                </Row>
                {isError && (
                    <Row className='justify-content-center'>
                        <div className={styles.error}>{form.errorSubmitting}</div>
                    </Row>
                )}
                {isSuccess && (
                    <Row className='justify-content-center'>
                        <div className={styles.success}>{form.successSubmitting}</div>
                    </Row>
                )}
            </Form>
            {showSpinner && <SpinnerComponent />}
        </Container>
    );
}

export default SalesForm;
