import React from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import useLanguageContext from "../../context/useLanguageContext";

function TeamMember({member, header}) {
    const { language } = useLanguageContext();
console.log(member)
    const members = member.map(props =>
        <Container className={styles.container}>
            <Row className={styles.body}>

                {//for image in the middle
                    /*<Col lg={4} className='d-flex justify-content-center'>*/}

                <Col lg={4}>
                    <Image roundedCircle className={styles.img} src={props.image} />
                </Col>
                <Col lg={8}>
                    <p className={styles.name}>{props.name}</p>
                    <p className={styles.paragraph}>
                        {props.infos}
                    </p>
                    <p className={styles.activity}>{props.activity}</p>
                    <ul>
                        {props.activitydescription && Object.keys(props.activitydescription).map((key,index) =>
                            <li key={index} className={styles.list}>
                                {props.activitydescription[key]}
                            </li>
                        )}
                    </ul>
                    <p className={styles.activity}>{language === 'en' ? 'Contact' : 'Kontakt'}</p>
                    <p>
                        {props.company !== ''  &&
                            <div>
                            {props.company} <br />
                            </div>}
                        {props.street !== ''  &&
                        <div>
                            {props.street} <br />
                        </div>}
                        {props.city !== ''  &&
                        <div>
                            {props.city} <br />
                        </div>}
                    </p>
                    <p>
                        E-Mail: <a className={styles.link} href={`mailto:${props.email}`}>{props.email}</a>
                        <br />Telefon: {props.phone}
                    </p>
                </Col>
            </Row>
        </Container>
    );

    return (
        <Container>
            <Row className='justify-content-center'>
                <h1 className={styles.header}>{header}</h1>
            </Row>
            {members}
        </Container>
    );
}

export default TeamMember;
