import React from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

function SpinnerComponent() {

    return (
        <Container className={styles.spinner}>
            <Spinner animation='border' role='status' variant='primary'>
            </Spinner>
        </Container>
    );
}

export default SpinnerComponent;
