import { useState, useEffect} from 'react';
import useApiDomainContext from "../context/useApiDomainContext";

export default function useFetchBackend(endpoint) {
    const {apiDomain} = useApiDomainContext();
    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            const response = await fetchContent();
            setData(buildArray(response));
        }
        getData()
    }, []);

    async function fetchContent() {
        const response = await fetch(`${apiDomain}/wp-json/flowdesk/${endpoint}`);
        return await response.json();
    }

    function buildArray(response) {
        if (response['ids']) {
            return response['ids'].map(id => {
                const object = response.property[id];
                if (object['pic'] && object['pic'][0]) {
                    const picId = object['pic'][0];
                    object.pic = response.document[picId]['file']
                    return object;
                } else
                    return object;

            })
        } else
            return [];
    }

    return data;
}
