import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './index.module.scss';
import Table from 'react-bootstrap/Table';
import useLanguageContext from "../../context/useLanguageContext";

export function ObjectDescription ({content}) {
    const { language } = useLanguageContext();

    return (
        <Container className={styles.container}>
            <h1><strong>{content.title}</strong></h1>
            <div className={styles.block}>
                <Table size='sm' borderless responsive>
                    <tbody >
                    {content.net &&
                        <tr>
                            <td><strong>{language === 'en' ? 'Net cold rent' : 'Nettokaltmiete'}</strong></td>
                            <td>{Intl.NumberFormat('de-DE', {style: 'currency',currency: 'EUR'}).format(content.net)}</td>
                        </tr>
                    }
                    {content.additionalCosts && (content.priceShow === 1 ?
                                <tr>
                                    <td><strong>{language === 'en' ? 'Additional costs' : 'Nebenkosten'}</strong></td>
                                    <td>{Intl.NumberFormat('de-DE', {style: 'currency',currency: 'EUR'}).format(content.additionalCosts)}</td>
                                </tr> : '' )
                    }
                    {content.area &&
                    <tr>
                        <td><strong>{language === 'en' ? 'Area' : 'Gesamtfläche'}</strong></td>
                        <td>{content.area} m²</td>
                    </tr>
                    }
                    {content.area2 &&
                        <tr>
                            <td><strong>{language === 'en' ? 'Shop area' : 'Ladenfläche'}</strong></td>
                            <td>{content.area2} m²</td>
                        </tr>
                    }
                    {content.area7 &&
                        <tr>
                            <td><strong>{language === 'en' ? 'Adjoining area' : 'Nebenfläche'}</strong></td>
                            <td>{content.area7} m²</td>
                        </tr>
                    }
                    {content.level &&
                    <tr>
                        <td><strong>{language === 'en' ? 'Level' : 'Etage'}</strong></td>
                        <td>{content.level}</td>
                    </tr>
                    }
                    {content.year &&
                    <tr>
                        <td><strong>{language === 'en' ? 'Year' : 'Baujahr'}</strong></td>
                        <td>{content.year}</td>
                    </tr>
                    }
                    {content.state &&
                    <tr>
                        <td><strong>{language === 'en' ? 'State' : 'Zustand'}</strong></td>
                        <td>{content.state}</td>
                    </tr>
                    }
                    {content.available &&
                        <tr>
                            <td><strong>{language === 'en' ? 'Availability from' : 'Verfügbarkeit ab'}</strong></td>
                            <td>{content.available}</td>
                        </tr>
                    }
                    {content.available2 &&
                        <tr>
                            <td><strong>{language === 'en' ? 'Availability from' : 'Verfügbarkeit ab'}</strong></td>
                            <td>{content.available2}</td>
                        </tr>
                    }
                    {content.provision &&
                        <tr>
                            <td><strong>{language === 'en' ? 'Brokerage commission' : 'Maklerprovision'}</strong></td>
                            <td>{content.provision}</td>
                        </tr>
                    }
                    {content.zip &&
                        <tr>
                            <td><strong>{language === 'en' ? 'Place' : 'Ort'}</strong></td>
                            <td>{content.zip} {content.federal}, {content.location}</td>
                        </tr>
                    }
                    {content.no &&
                        <tr>
                            <td><strong>{language === 'en' ? 'Object number' : 'Objektnummer'}</strong></td>
                            <td>{content.no}</td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </div>
        </Container>
    )
}

export default ObjectDescription;
