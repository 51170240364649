import React from 'react';
import styles from './index.module.scss';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

function ButtonCenter({position, onclick, type, link, value, disabled,target}) {
    return (
        <Row className={`m-auto ${position}`}>
            <Button className={styles.button}
                    onClick={!disabled&&onclick}
                    type={type}
                    as={Link}
                    to={link}
                    disabled={disabled}
                    target={target}
                    
            >
                {value.toUpperCase()}
            </Button>
        </Row>
    );
}

export default ButtonCenter;
