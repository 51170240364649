import React, { useState } from 'react';
import styles from './index.module.scss'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ObjectCard from '../ObjectCard';
import Col from 'react-bootstrap/Col';
import ButtonCenter from '../ButtonCenter';
import useLanguageContext from '../../context/useLanguageContext';

function ObjectResult(props) {
    const {language} = useLanguageContext();
    const count = Object.keys(props.cards).length;
    const [maxCards, setMaxCards] = useState(6);

    const cards = props.cards.slice(0, maxCards).map(prop =>
        <Col xs='12' sm='6' lg='4' className='mt-4' key={`${prop.id}`}>
            <ObjectCard image={prop.pic}
                        title={props.title === null ? '' : prop.title}
                        content={`Nettokaltmiete ${unit(prop)}`}
                        content2={propertyTyp(prop)}
                        key={'card' + prop.id}
                        linkId={prop.id}
            />
        </Col>
    )


    function unit(res) {
        if (res['net']) {
            return Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(res.net)
        } else
            return 'auf Anfrage'
    }

    function propertyTyp(res) {
        if (res.area2) {
            if (res['type'] === '1') {
                return 'Ladenfläche ' + res.area2 + ' m²';
            } else {
                return 'Gewerbefläche ' + res.area2 + ' m²';
            }
        }
        if (res.area) {
            return 'Gesamtfläche ' + res.area + ' m²'
        }
        return 'Fläche ' + '0' + ' m²';
    }

    function showMoreHandler() {
        setMaxCards(maxCards + 3)
    }

    return (
        <Container className={styles.container}>
            <Row className={styles.count}>
                {count} {language === 'en' ? 'OFFERS' : 'ANGEBOTE'}
            </Row>
            <Row className={styles.cards}>
                {cards}
            </Row>
            <Row className={styles.btn}>
                {maxCards < props.cards.length &&
                <ButtonCenter
                    position='justify-content-center'
                    value={language === 'en' ? 'MORE' : 'MEHR'}
                    onclick={showMoreHandler}
                    disabled={maxCards >= props.cards.length}
                />
                }
            </Row>
        </Container>
    )
}

export default ObjectResult;
