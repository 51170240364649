import React, { useEffect, useState } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import TextCenter from '../../components/TextCenter';
import BasicTable from '../../components/BasicTable';
import useLanguageContext from "../../context/useLanguageContext";

function SaleRequest() {
    const {apiDomain} = useApiDomainContext()
    const [toppic, setToppic] = useState({});
    const [purchase, setPurchase] = useState({});
    const [table, setTables] = useState([]);
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress SaleRequest page
    useEffect(() => {
        async function getData() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1619') : await fetchContent('/wp-json/acf/v3/pages/106');
            const acf = json.acf;
            //console.log('TABELLENINHALT', acf.table_1.header.map(title => console.log(title.c) ), acf.table_2)

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            })

            setPurchase({
                header: acf.purchase_search_header,
                content: acf.purchase_search_content
            });

            setTables(acf);
        }
        getData()
    }, [language]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1619') : await fetchContent('/wp-json/wp/v2/pages/106');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return(
        <div>
            <div>
                <Toppic header={toppic.header} image={toppic.image} content={toppic.content} />
                {/*<Toppic data={toppic} />*/}
            </div>
            <div style={{marginTop: '40px'}}>
                <TextCenter data={purchase} />
            </div>
            <div style={{marginTop: '40px'}}>
                <h1 style={{fontWeight: '600', backgroundColor: '#F4F4F4'}}  className='p-3' >{table.table_1_header}</h1>
            </div>
            <div  style={{marginTop: '30px'}}>
                <BasicTable table={table.table_1} />
            </div>
            <div style={{marginTop: '40px'}}>
                <h1 style={{fontWeight: '600', backgroundColor: '#F4F4F4'}}  className='p-3' >{table.table_2_header}</h1>
            </div>
            <div  style={{marginTop: '40px'}}>
                <BasicTable table={table.table_2} />
            </div>
            <div style={{marginTop: '40px'}}>
                <h1 style={{fontWeight: '600', backgroundColor: '#F4F4F4'}}  className='p-3' >{table.table_3_header}</h1>
            </div>
            <div  style={{marginTop: '40px'}}>
                <BasicTable table={table.table_3} />
            </div>
        </div>
    )

}

export default SaleRequest;
