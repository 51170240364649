export const headerGerman = {
    aboutUs: 'Über Uns',
    engagement: 'Unser Engagement',
    contact: 'Kontakt',
    rent: 'VERMIETUNG',
    sale: 'VERKAUF',
    news: 'AKTUELLES',
    call: 'Anrufen!'
};

export const headerEnglish = {
    aboutUs: 'About Us',
    engagement: 'Our Engagement',
    contact: 'Contact',
    rent: 'RENTALES',
    sale: 'SALES',
    news: 'NEWS',
    call: 'Call!'
};