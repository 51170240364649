export const propFormGerman = {
    heading1: 'Ihre vakante Gewerbeimmobilie',
    heading2: 'Ihre Kontaktdaten',
    city: 'Ort',
    street: 'Straße, Hausnr.',
    salesArea: 'Verkaufsfläche',
    ancillaryArea: 'Nebenfläche',
    basementArea: 'Kellerfläche',
    freeFrom: 'frei ab',
    netto: 'Nettokaltmiete €',
    heatingCosts: 'Heizkosten €',
    operatingCosts: 'Betriebskosten €',
    heatingType: 'Heizungsart',
    propertyDescription: 'Objektbeschreibung',
    salesHeading: 'Ihr Verkaufsobjekt',
    construction: 'Baujahr',
    typeOfHeating: 'Heizungsart',
    propertySize: 'Grundstücksgröße m²',
    totalRentalSpace: 'Gesamtmietfläche m²',
    commercialArea: 'Gewerbefläche m²',
    livingSpace: 'Wohnfläche m²',
    yearlyRentalIncomeNet: 'Jahresmieteinnahme netto €',
    yearlyRentalIncomeGross: 'Jahresmieteinnahme brutto €',
    presentationOfPrices: 'Kaufpreisvorstellung €',
    salutationLabel: 'Anrede',
    salutation: ['Frau', 'Herr'],
    company: 'Firma',
    firstname: 'Vorname *',
    lastname: 'Nachname *',
    contactStreet: 'Straße, Hausnr. *',
    postCode: 'PLZ *',
    contactCity: 'Ort *',
    phone: 'Telefon *',
    fax: 'Fax',
    mail: 'E-mail *',
    weAre: 'Ich bin / Wir sind:',
    owner: 'Eigentümer / Vermieter',
    management: 'Hausverwalter',
    pretenant: 'Vormieter',
    send: 'Absenden',
    errorSubmitting: 'Es ist ein Fehler aufgetreten. Bitte versuchen is es erneut.',
    successSubmitting: 'Ihre Anfrage wurde erfolgreich verschickt.',
    requiredField: '* Diese Felder müssen ausgefüllt werden.',
    privacy: 'Die uns übersendeten Daten werden streng vertraulich behandelt.' +
        'Während des Datentransportes sind jedoch keine umfassenden' +
        'Schutzvorkehrungen möglich, somit ist ein rechtswidriger Zugriff' +
        'Dritter nicht vollkommen auszuschließen. Zum 01.07.1997 trat das' +
        'neue Multimediagesetz in Kraft. Danach dürfen u.a. personenbezogene' +
        'Daten nur mit Einwilligung der betreffenden Person gespeichert und' +
        'weiterverarbeitet werden. Durch Betätigen Ihrer Eingaben in Formularen,' +
        'mit dem Button "Senden" o.ä. erklären Sie sich hiermit einverstanden.',
    privacyCommit1: 'Ich erkläre mich mit der Verarbeitung meiner persönlichen Daten gemäß der ',
    privacyLink: 'Datenschutzerklärung',
    privacyCommit2: ' einverstanden'
};

export const propFormEnglish = {
    heading1: 'Your vacant commercial property',
    heading2: 'your contact information',
    city: 'City',
    construction: 'Year of construction *',
    street: 'Street',
    salesArea: 'Sales area',
    ancillaryArea: 'Ancillary area',
    basementArea: 'Basement area',
    freeFrom: 'Free from',
    netto: 'Net cold rent €',
    heatingCosts: 'Heating costs €',
    operatingCosts: 'Operating costs €',
    heatingType: 'Heating type',
    propertyDescription: 'Property description',
    propertyDescription: 'Objektbeschreibung *',
    salesHeading: 'Your sales object',
    construction: 'Construction *',
    typeOfHeating: 'Heating type *',
    propertySize: 'Property Size m² *',
    totalRentalSpace: 'Total rental Space m² *',
    commercialArea: 'Commercial area m² *',
    livingSpace: 'Living space m² *',
    yearlyRentalIncomeNet: 'Yearly rental income net € *',
    yearlyRentalIncomeGross: 'Yearly rental income gross € *',
    presentationOfPrices: 'Presentation of prices € *',
    salutationLabel: 'Salutauion',
    salutation: ['Mrs.', 'Mr.'],
    company: 'Company',
    firstname: 'Firstname',
    lastname: 'Lastname',
    contactStreet: 'Street',
    postCode: 'Post code',
    contactCity: 'City',
    phone: 'Phone',
    fax: 'Fax',
    mail: 'E-mail',
    weAre: 'I am / We are:',
    owner: 'Owner / Lessor',
    management: 'Property management',
    pretenant: 'Pretenant',
    send: 'send',
    errorSubmitting: 'An error has occurred. Please try it again.',
    successSubmitting: 'Your request was sent successfully.',
    requiredField: '* These fields must be filled in.'
}