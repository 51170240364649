import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Heading from '../Heading';
import axios from 'axios';
import useApiDomainContext from '../../context/useApiDomainContext';
import {
  subContactFormGerman,
  subContactFormEnglish,
} from '../../models/subContactFormModel';
import useLanguageContext from '../../context/useLanguageContext';
import {
  formValMsgGerman,
  formValMsgEnglish,
} from '../../models/formValidationModel';
import SpinnerComponent from '../SpinnerComponent';

export function SubContactForm({ propertyId }) {
  const { language } = useLanguageContext();
  const { register, handleSubmit, errors } = useForm();
  const { apiDomain } = useApiDomainContext();
  const [contactForm, setContactForm] = useState(subContactFormGerman);
  const [formVal, setFormVal] = useState(formValMsgGerman);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (language === 'en') {
      setContactForm(subContactFormEnglish);
      setFormVal(formValMsgEnglish);
    } else {
      setContactForm(subContactFormGerman);
      setFormVal(formValMsgGerman);
    }
  }, [language]);

  const handleReset = () => {
    formRef.current.reset();
    recaptchaRef.current.reset();
    setShowSpinner(false);
  };

  function createCaptcha(value) {
    setDisableButton(false);
  };

  function onSubmit(data) {
    setIsError(false);
    setDisableButton(true);
    setShowSpinner(true);
    setIsSuccess(false);

    const bodyFormData = new FormData();
    bodyFormData.set('type', 'contact');
    bodyFormData.set('mail', data.email);
    bodyFormData.set('surname', data.name);
    bodyFormData.set('info', data.message);
    bodyFormData.set('topic', data.topic);

    axios
      .post(`${apiDomain}/wp-json/flowdesk/customer`, bodyFormData)
      .then((response) => {
        //console.log('RESPONSE FROM BACKEND', response);
        setDisableButton(false);

        if (response.data.id) {
          setIsError(false);
          handleReset();
          setIsSuccess(true);
        } else {
          setIsError(true);
          setIsSuccess(false);
          setShowSpinner(false);
        }
      })
      .catch((error) => {
        console.error('contact form error', error);
        setDisableButton(false);
        setIsError(true);
        setIsSuccess(false);
        setShowSpinner(false);
      });
  }

  return (
    <Container className={styles.container}>
      <Form onSubmit={handleSubmit(onSubmit)} id='subContactForm' ref={formRef}>
        <Row className={styles.heading}>
          <Heading
            className={styles.heading}
            heading={contactForm.heading}
            fontSize={60}
          />
        </Row>
        <Row style={{margin: '1rem 0'}}>
          <h1 style={{fontSize: '32px'}}>{contactForm.headline1}</h1>
        </Row>
        <Row style={{margin: '1rem 0'}}>
          <h4>{contactForm.headline2}</h4>
        </Row>
        <Row className={styles.formgroup}>
          <Col md={6}>
            <Form.Group controlId='name'>
              <Form.Label>{contactForm.name}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='name'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.name && (
                <div className={styles.error}>{errors.name.message}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId='email'>
              <Form.Label>{contactForm.email}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='email'
                name='email'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: formVal.email,
                  },
                })}
              />
              {errors.email && (
                <div className={styles.error}>{errors.email.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={styles.formgroup}>
          <Col>
            <Form.Group controlId='topic'>
              {/*<Form.Control
                className={styles.select}
                as="select"
                name="topic"
                ref={register({ required: true })}
              >
                <option className={styles.select}>Thema wählen</option>
                <option className={styles.select}>Thema 1</option>
                <option className={styles.select}>Anderes Thema</option>
              </Form.Control>*/}
              <Form.Label>{contactForm.topic}</Form.Label>
              <Form.Control
                className={styles.field}
                as='input'
                type='text'
                name='topic'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 100,
                    message: formVal.maxLength100,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.topic && (
                <div className={styles.error}>{errors.topic.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className={styles.formgroup}>
          <Col>
            <Form.Group controlId='message'>
              <Form.Label>{contactForm.message}</Form.Label>
              <Form.Control
                as='textarea'
                rows='3'
                name='message'
                ref={register({
                  required: {
                    value: true,
                    message: formVal.required,
                  },
                  maxLength: {
                    value: 1000,
                    message: formVal.maxLength1000,
                  },
                  minLength: {
                    value: 2,
                    message: formVal.minLength2,
                  },
                })}
              />
              {errors.message && (
                <div className={styles.error}>{errors.message.message}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{margin: '1rem 0'}}>
          <div>{contactForm.requiredField}</div>
        </Row>
        <Row className={styles.paragraph}>
          <Col>
            <p>
              Die uns übersendeten Daten werden streng vertraulich behandelt. Während des Datentransportes
              sind jedoch
              keine umfassenden Schutzvorkehrungen möglich, somit ist ein rechtswidriger Zugriff Dritter
              nicht
              vollkommen auszuschließen. Zum 01.07.1997 trat das neue Multimediagesetz in Kraft. Danach
              dürfen u.a.
              personenbezogene Daten nur mit Einwilligung der betreffenden Person gespeichert und
              weiterverarbeitet
              werden.
              Durch Bestätigen Ihrer Eingaben in Formularen mit dem Button "Absenden" o. ä., erklären Sie sich hiermit einverstanden.
            </p>
          </Col>
        </Row>
        <Row className={styles.formgroup}>
          <Col>
            <Form.Group className={styles.checkbox}>
              <Form.Check custom className="mb-2"
                          type="checkbox"
                          id="custom4"
                          name='consent'
                          ref={register({ required: true })}
                          label={<span>Ich erkläre mich mit der Verarbeitung meiner persönlichen Daten gemäß der <Link to={'/datenschutz'}>Datenschutzerklärung</Link> einverstanden</span>}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* <Row className={styles.formgroup}>
          <Col>
            <Form.Group className={styles.checkbox}>

              <Form.Check custom className="mb-2" type="checkbox" id="custom5" label="Ich bin kein Roboter (Captcha)"
                       ref={register({required: true})} />
            </Form.Group>
          </Col>
        </Row>*/}

        <ReCAPTCHA
          hl={language === 'en' ? 'en' : 'de'}
          sitekey='6Lf_7aIZAAAAAEwc2Y1VRcJXwag6R6c4wmclzhXo'
          onChange={createCaptcha}
          ref={recaptchaRef}
        />

        <Button type='submit' className={styles.button} disabled={disableButton}>
          {contactForm.send.toUpperCase()}
        </Button>
        {isError && (
          <Row className='justify-content-center'>
            <div>{contactForm.errorSubmitting}</div>
          </Row>
        )}
        {isSuccess && (
          <Row className='justify-content-center'>
            <div className={styles.success}>
              {contactForm.successSubmitting}
            </div>
          </Row>
        )}
      </Form>
      {showSpinner && <SpinnerComponent />}
    </Container>
  );
}

export default SubContactForm;
