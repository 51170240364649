import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './index.module.scss';

function ReferenceExamples({examples}) {
    return (
        <Container className={styles.box}>
            <p>{examples}</p>
        </Container>
    );
}

export default ReferenceExamples;
