export const footerGerman = {
    home: 'Startseite',
    news: 'Aktuelles',
    index: 'HandelsIndex',
    aboutUs: 'Über Uns',
    engagement: 'Unser Engagement',
    privacy: 'Datenschutz',
    impressum: 'Impressum',
    rent: 'Vermietung',
    sale: 'Verkauf',
    call: 'Anrufen',
    contact: 'Kontakt'
};

export const footerEnglish = {
    home: 'Home',
    news: 'News',
    index: 'Trading Index',
    aboutUs: 'About Us',
    engagement: 'Our Engagement',
    privacy: 'Privacy',
    impressum: 'Imprint',
    rent: 'Rentales',
    sale: 'Sales',
    call: 'Call',
    contact: 'Contact'
};