import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import useApiDomainContext from "../../context/useApiDomainContext";
import { useParams } from 'react-router-dom';
import Toppic from '../../components/Toppic';
import OfferOfDay from '../../components/OfferOfDay';
import useFetchBackend from '../../services/useFetchBackend';

export default function BlogArtikel() {
    const {apiDomain} = useApiDomainContext();
    const {id} = useParams();
    const [toppic, setToppic] = useState({});
    const [blog, setBlog] = useState({});
    const backendDailyOffers = useFetchBackend('property?action=search&sale=Miete&full=1');
    const [title, setTitle] = useState('');

    // fetch Wordpress News page
    useEffect(() => {
        async function updateState() {
            const json = await fetchContent('/wp-json/acf/v3/pages/61');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            // fetch Wordpress Blog posts
            const blogs = await fetchContent(`/wp-json/wp/v2/posts/${id}`)
            setBlog(blogs.content.rendered);
            setTitle(blogs.title.rendered);

        }
        updateState().catch(err => console.error(err));
    }, []);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            <Container style={{marginTop: '40px', fontSize: '18px'}} className='d-flex justify-content-center'>
                <div dangerouslySetInnerHTML={{__html: blog}} />
            </Container>
            <div style={{marginTop: '20px'}}>
                {backendDailyOffers[0] && <OfferOfDay cards={backendDailyOffers} />}
            </div>
        </div>
    )
}
