import React from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Facts({fact}) {

    const facts =  fact.map(props =>
            <Col lg md={4} sm={6} xs={6} key={props.id}>
                <Col>
                    <Row className={`justify-content-center ${styles.numbers}`}>
                        {props.number}
                    </Row>
                </Col>
                <Col>
                    <Row className="justify-content-center">
                        <div className={styles.line}></div>
                    </Row>
                </Col>
                <Col>
                    <Row className={`justify-content-center text-center ${styles.description}`}>
                        {props.description}
                    </Row>
                </Col>
            </Col>
        );

    return (
        <Container className={styles['facts-container']}>
            <Row className={'justify-content-center'}>
                {facts}
            </Row>
        </Container>
    )
}

export default Facts;
