import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './index.module.scss';

function TextCenter({data}) {
    return (
        <Container className={styles.box}>
            {data.header === '' ?
                <div>
                    <p>{data.content}</p>
                </div>
                :
                <div>
                    <h1>{data.header}</h1>
                    <p>{data.content}</p>
                </div>
                }
        </Container>
    );
}

export default TextCenter;
