export const coCoGerman = {
    heading: 'Cookie',
    content: 'Diese Seite verwendet Cookies, um die Funktionialität zu gewährleisten. Für weitere Informationen lesen Sie bitte unsere ',
    link: 'Datenschutzerklärung.',
    confirm: 'OK'
};

export const coCoEnglish = {
    heading: 'Cookie',
    content: 'This site uses cookies to ensure functionality. To learn more, please read our ',
    link: 'Privacy Policy.',
    confirm: 'OK'
};