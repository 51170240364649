import React, {useState, useEffect } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
import Toppic from '../../components/Toppic';
import ImageText from '../../components/ImageText';
import TextCenter from "../../components/TextCenter";
import HeaderTextButton from '../../components/HeaderTextButton';
import useFetchSuccesses from '../../services/useFetchSuccesses';
import SuccessCardBlock from '../../components/SuccessCardBlock';
import Heading from '../../components/Heading';
import styles from '../Start/index.module.scss';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import useLanguageContext from "../../context/useLanguageContext";

function Sale() {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    const [consulting, setConsulting] = useState({});
    const [search, setSearch] = useState({});
    const [offer, setOffer] = useState({});
    const [salesObject, setSalesObject] = useState({});
    const saleSuccessesCard = useFetchSuccesses('?categories=11');
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress Sale page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1545') : await fetchContent('/wp-json/acf/v3/pages/58');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            setConsulting({
                header: acf.consulting_header,
                content: acf.consulting_content
            });

            setSearch({
                header1: acf.search_header,
                content: acf.search_content
            });

            setOffer({
                content: acf.offer_content
            });

            setSalesObject({
                header1: acf.sales_object_header,
                content: acf.sales_object_content
            });
        }
        updateState().catch(err => console.error(err));
    }, [language]);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1545') : await fetchContent('/wp-json/wp/v2/pages/58');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return (
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            <div style={{marginTop: '20px'}}>
                <TextCenter data={consulting} />
            </div>
            <div style={{marginTop: '20px'}}>
                <HeaderTextButton link='/kontakt' content={search} button={language === 'en' ? 'Contact us' : 'Kontaktieren Sie uns'} />
            </div>
            <Container  style={{marginTop: '0px', fontSize: '18px'}}>
                <TextCenter data={offer} />
            </Container>
            <div style={{marginTop: '20px'}}>
                <HeaderTextButton link='/verkauf/ihr-verkaufsobjekt' content={salesObject} button={language === 'en' ? 'more' : 'mehr'} />
            </div>
            <div>
                <div style={{marginTop: '20px'}}>
                    <Heading fontSize={styles.fontSize50} heading={language === 'en' ? 'SALES SUCCESSES' : 'VERKAUFSERFOLGE'}/>
                </div>
                <div style={{marginTop: '20px', marginBottom: '70px'}}>
                    <SuccessCardBlock content={saleSuccessesCard} link={'/verkauf/verkaufserfolge'}/>
                </div>
            </div>
        </div>
    )
}

export default Sale;
