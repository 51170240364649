import React from 'react';
import styles from './index.module.scss'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonCenter from "../ButtonCenter";
import Testimonials from '../Testimonials';

function Reference({reference, button, link}) {
    return (
        <div className={styles.div}>
            <Container fluid className='d-flex flex-wrap'>
                <Col lg={5}>
                    <Testimonials image={reference.image1} content={reference.content1}
                                  company={reference.company1} name={reference.name1} bodyBackground={'#F4F4F4'} />
                </Col>
                <Col lg={7} className='align-self-center mt-2'>
                    <div>
                        <Row className={styles.header}>
                            {reference.header2}
                        </Row>
                        <Row className={styles.content}>
                            {reference.content2}
                        </Row>
                        <Row className={styles.btn}>
                            <ButtonCenter
                                position='justify-content-center'
                                value={button}
                                link={link}
                            />
                        </Row>
                    </div>
                </Col>
            </Container>
        </div>
    )
}
export default Reference;
