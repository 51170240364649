import React from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';

function BasicTable({table}) {
    return (
        <Container>
            <Table bordered={true}>
                <thead style={{backgroundColor: '#ccc'}}>
                <tr>
                    {table &&
                    table.header.map(title =>
                        <th>{title.c}</th>)
                    }
                </tr>
                </thead>
                <tbody>
                    {table &&
                    table.body.map(array =>
                        <tr>{array.map(content =>
                            <td>{content.c}</td>)}
                        </tr>)
                    }
                </tbody>
            </Table>
        </Container>
    )
}

export default BasicTable;
