import React from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import img from '../../assets/Marker_big.svg';
import modifyTextForLinks from '../../services/modifyTextForLinks';
import Debug from '../Debug';
import Card from 'react-bootstrap/Card';

function EngagementCorner({content = {header: '', p1: '', p2: ''}}) {

    const paragraph2 = modifyTextForLinks(content.p2);

    return (
        <div className={styles.background}>
            <Container className={styles.container}>
                <div className={styles['corner-border']}>
                    <Card
                        style={{
                            backgroundImage: `url(${content.image})`,
                            backgroundSize: '100% 100%'
                        }}
                        className={styles.layer1}>
                        <Card.Body className={styles.layer2}>
                            <Card.Title className={styles.header1}>{content.header}</Card.Title>
                            <Card.Text className={styles.paragraph}>{content.p1}
                            </Card.Text>
                            <Card.Text className={styles.paragraph}>{paragraph2}
                            </Card.Text>
                            {content.image_1 &&
                            <div>
                                <Row>
                                    <Col>
                                        <Image className={styles.image} src={content.image_1} />
                                    </Col>
                                    <Col>
                                        <Image className={styles.image} src={content.image_2} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Image className={styles.image} src={content.image_3} />
                                    </Col>
                                    <Col>
                                        <Image className={styles.image} src={content.image_4} />
                                    </Col>
                                </Row>
                            </div>
                            }

                        </Card.Body>
                    </Card>
                </div>

            </Container>
        </div>
    )
}

export default EngagementCorner;
