import React, {useState, useEffect } from 'react';
import useApiDomainContext from "../../context/useApiDomainContext";
//import BlogPreviewBlock from '../../components/BlogPreviewBlock';
import PressPreviewBlock from '../../components/PressPreviewBlock';
import OfferOfDay from '../../components/OfferOfDay';
import TestimonialsBlock from '../../components/TestimonialsBlock';
import Toppic from '../../components/Toppic';
import Heading from '../../components/Heading';
import useFetchBackend from '../../services/useFetchBackend';
import useFetchSuccesses from '../../services/useFetchSuccesses';
import useLanguageContext from '../../context/useLanguageContext';

function News () {
    const {apiDomain} = useApiDomainContext();
    const [toppic, setToppic] = useState({});
    const [head, setHead] = useState({});
    //const [blog, setBlogs] = useState([]);
    const [press, setPress] = useState([]);
    const backendDailyOffers = useFetchBackend('property?action=search&sale=Miete&full=1');
    const rentSuccesses = useFetchSuccesses('?categories=6');
    const saleSuccesses = useFetchSuccesses('?categories=7');
    const [title, setTitle] = useState('');
    const { language } = useLanguageContext();

    // fetch Wordpress News page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/acf/v3/pages/1290') : await fetchContent('/wp-json/acf/v3/pages/61');
            const acf = json.acf;

            setToppic({
                header: acf.head_header,
                content: acf.head_content,
                image: acf.head_image
            });

            setHead({
                heading1: acf.blog_heading,
                heading2: acf.press_heading
            });

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    // fetch Wordpress Blog posts
    /*useEffect(() => {
        async function updateState() {
            const json = await fetchContent('/wp-json/wp/v2/posts?categories=3&_embed');

            const blog = json.map(b => ({
                image: b._embedded['wp:featuredmedia']?
                    b._embedded['wp:featuredmedia'][0].source_url : '',
                title: b.title.rendered,
                date: b.date,
                content: <div dangerouslySetInnerHTML={{__html: b.excerpt.rendered}}/>,
                link: b.id
            }))

            setBlogs(blog);

        }
        updateState().catch(err => console.error(err));
    }, []);*/

    // fetch Wordpress Press posts
    useEffect(() => {
        async function updateState() {
            const json = await fetchContent('/wp-json/wp/v2/posts?categories=5&_embed');

            const presses = json.map(press => ({
                image: press._embedded['wp:featuredmedia']?
                    press._embedded['wp:featuredmedia'][0].source_url : '',
                title: press.title.rendered,
                date: press.date,
                content: <div dangerouslySetInnerHTML={{__html: press.excerpt.rendered}}/>,
                link: press.acf.linkart,
                weblink: press.acf.weblink,
                pdf: press.acf.pdf_download,
                id: press.id
            }))

            setPress(presses);

        }
        updateState().catch(err => console.error(err));
    }, []);

    //fetch the title of the page
    useEffect(() => {
        async function updateState() {
            const json = language === 'en' ? await fetchContent('/wp-json/wp/v2/pages/1290') : await fetchContent('/wp-json/wp/v2/pages/61');
            setTitle(json.title.rendered)

        }
        updateState().catch(err => console.error(err));
    }, [language]);

    async function fetchContent(url) {
        const response = await fetch(`${apiDomain}${url}`);
        return await response.json();
    }

    if (title){document.title = title}

    return(
        <div>
            <Toppic image={toppic.image} header={toppic.header} content={toppic.content} />
            {/*This section is not needed atm*/}

            {/*<div style={{marginTop: '140px'}}>
                <Heading heading={head.heading1}/>
            </div>
            <div>
                <BlogPreviewBlock blog={blog}/>
            </div>*/}

            <div style={{marginTop: '40px'}}>
                <Heading heading={head.heading2}/>
            </div>
            <div>
                <PressPreviewBlock press={press}/>
            </div>
            <div style={{marginTop: '40px'}}>
                {backendDailyOffers[0] && <OfferOfDay cards={backendDailyOffers} />}
            </div>
            <div style={{marginTop: '40px', marginBottom: '40px'}}>
                <TestimonialsBlock rent={rentSuccesses} sale={saleSuccesses} />
            </div>
        </div>
    )
}

export default News;
