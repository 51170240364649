import React from 'react';
import Card from 'react-bootstrap/Card';
import styles from './index.module.scss';

function NetworkSliderCard(props) {
    return (
        <Card style={{maxWidth: '350px'}} className={styles['slider-card-component']} >
            <Card.Img style={{width: '180px', height: '180px', objectFit: 'contain'}} variant='top' src={props.image} />
            <Card.Body>
                <Card.Text className={styles['slider-card-title']}>
                    {props.title}
                </Card.Text>
                <Card.Text className={styles['slider-card-text']}>
                    {props.text}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default NetworkSliderCard;