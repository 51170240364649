import React from 'react';
import styles from './index.module.scss'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

function Toppic({image= '', header = '', content = ''}) {
    return (
        <Container fluid className={styles.container}>
            <Card className={styles['card-container']}>
                <img alt='' className={styles.layer1} src={image}/>
                <Card.Body className={styles.layer2}>
                    <Card.Title className={styles.header}>{header}</Card.Title>
                    <Card.Text className={styles.content}>{content}</Card.Text>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Toppic;
