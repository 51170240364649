import React, { useEffect, useState } from 'react';
import '../../custom.scss';
import Main from '../Main';
import LanguageContextProvider from '../../context/LanguageContextProvider';
import ApiDomainContextProvider from '../../context/ApiDomainContextProvider';
import * as Cookies from 'js-cookie';

function App() {
    const [isConsent, setIsConsent] = useState(false);

    useEffect(() => {
        if (Cookies.get('confirmation') === 'true') {
            setIsConsent(true);
        }
    }, []);

    if (isConsent) {
        return (
            <ApiDomainContextProvider>
                <LanguageContextProvider>
                    <Main setConsent={setIsConsent} />
                </LanguageContextProvider>
            </ApiDomainContextProvider>
        );
    }
    else {
        return (
            <ApiDomainContextProvider>
                <LanguageContextProvider>
                    <Main setConsent={setIsConsent} />
                </LanguageContextProvider>
            </ApiDomainContextProvider>
        );
    }
}

export default App;
