import React from 'react';
import styles from './index.module.scss'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonCenter from "../ButtonCenter";

function HeaderTextButton({content, link, button}) {
    return (
        <div className={styles.div}>
            <Container className={styles.container}>
                <Row className={styles.header}>
                    {content.header1}
                </Row>
                <Row className={styles.header2}>
                    {content.header2}
                </Row>
                <Row>
                    <Col md></Col>
                    <Col md sm={12}>
                        <Row className={styles.content}>
                            <Col>
                                {content.content}
                            </Col>
                        </Row>
                        <Row className={styles.btn}>
                            <ButtonCenter
                                position='justify-content-center'
                                value={button}
                                link={link}
                            />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default HeaderTextButton;
