import React from 'react';
import styles from './index.module.scss'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

function ObjectSlider({pics}) {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#FE9900" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#FE9900" }}
                onClick={onClick}
            />
        );
    }

    const pictures = pics.map(pic =>
        <div key={pic.id}>
            <Image src={pic.file} className={styles.images}/>
            <p className={styles.text}>{pic.title}</p>
        </div>
    );

    const settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SampleNextArrow />,
        nextArrow: <SamplePrevArrow />,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                    centerMode: false
                }
            }
        ]
    };

    return(
        <Container className={styles.container}>
            <Slider {...settings}>
                {pictures}
            </Slider>
        </Container>
    )
}

export default ObjectSlider;