import React from 'react';
import styles from './index.module.scss'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Heading from '../Heading';
import Testimonials from '../Testimonials';
import ButtonCenter from '../ButtonCenter';
import Arrow from '../../assets/Icon_arrow_forward.svg';
import Link from '../Link';
import useLanguageContext from "../../context/useLanguageContext";

function TestimonialsBlock(props) {
    const { language } = useLanguageContext();
    const rent = props.rent;
    const sale = props.sale;
    const reLen = Object.keys(rent).length;
    const saLen = Object.keys(sale).length;

    const reStart = getRandomNumber(reLen);
    const reEnd = reStart + 1;
    const saStart = getRandomNumber(saLen);
    const saEnd = saStart + 1;

    function getRandomNumber(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Row className={styles.heading}>
                        <Heading heading={language === 'en' ? 'RENTAL SUCCESSES' : 'VERMIETUNGSERFOLGE'}/>
                    </Row>
                    <Row className={styles.card}>
                        {rent.slice(reStart, reEnd).map((card) =>
                            <Testimonials key={`${card.id}`}
                                          image={card.image}
                                          name={`${card.prename} ${card.surname}`}
                                          company={card.company}
                                          content={card.content}
                                          bodyBackground={'rgba(2,103,171, 0.5)'}
                            />
                        )}
                    </Row>
                    <Row className={styles.more}>
                        <Link image={Arrow}
                              content={language === 'en' ? 'MORE RENTAL SUCCESSES' : 'MEHR VERMIETUNGSERFOLGE'}
                              link='/vermietung/vermietungserfolge' />
                    </Row>
                    <Row className={styles.btn1}>
                        <ButtonCenter
                            position='justify-content-center'
                            value={language === 'en' ? 'WHAT DO YOU WANT TO RENT?' : 'WAS MÖCHTEN SIE VERMIETEN?'}
                            link='/vermietung/ihr-vermietungsobjekt' />
                    </Row>
                </Col>
                <Col>
                    <Row className={styles.heading}>
                        <Heading heading={language === 'en' ? 'SALES SUCCESSES' : 'VERKAUFSERFOLGE'}/>
                    </Row>
                    <Row className={styles.card}>
                        {sale.slice(saStart, saEnd).map((card) =>
                            <Testimonials key={`${card.id}`}
                                          image={card.image}
                                          name={`${card.prename} ${card.surname}`}
                                          company={card.company}
                                          content={card.content} bodyBackground={'rgba(2,103,171, 0.5)'} />
                        )}
                    </Row>
                    <Row className={styles.more}>
                        <Link image={Arrow}
                              content={language === 'en' ? 'MORE SALES SUCCESSES' : 'MEHR VERKAUFSERFOLGE'}
                              link='/verkauf/verkaufserfolge' />
                    </Row>
                    <Row className={styles.btn2}>
                        <ButtonCenter
                            position='justify-content-center'
                            value={language === 'en' ? 'WHAT DO YOU WANT TO SELL?' : 'WAS MÖCHTEN SIE VERKAUFEN?'}
                            link='/verkauf/ihr-verkaufsobjekt'
                        />
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default TestimonialsBlock;
