import React from 'react';
import styles from './index.module.scss';
import Card from 'react-bootstrap/Card';
import {Link, Link as link} from 'react-router-dom';
import Button from "react-bootstrap/Button";
import useLanguageContext from '../../context/useLanguageContext';

function SuccessCard(props) {
    const { language } = useLanguageContext();

    return (
        <Card className={styles['card-component']}>
            <Card.Img variant='top' src={props.image} className={styles.image} />
            <Card.Body className={styles['card-body']}>
                <Card.Text className={styles['card-title']}>
                    {props.title}
                </Card.Text>
                <Card.Text className={styles['card-name']}>
                    {props.name}
                </Card.Text>
                {props.link &&
                <div className='justify-content-center row mt-auto'>
                    <Button as={Link} to={props.link} className={styles.button}>{language === 'en' ? 'MORE' : 'MEHR'}</Button>
                </div>
                }
            </Card.Body>
        </Card>
    )
}

export default SuccessCard;
