import { useState, useEffect} from 'react';
import useApiDomainContext from "../context/useApiDomainContext";

export default function useFetchSuccesses(category) {
    const {apiDomain} = useApiDomainContext();
    const [data, setData] = useState([]);

    useEffect(() => {
        async function updateState() {
            const json = await fetchContent();

            const contents = json.map(content => ({
                image: content.acf.image,
                prename: content.acf.first_name,
                surname: content.acf.last_name,
                company: content.acf.company,
                content: content.acf.content,
                property_image: content.acf.property_image,
                property_company: content.acf.property_company,
                street: content.acf.street,
                city: content.acf.city,
                district: content.acf.district,
                statement: content.acf.statement
            }))

            setData(contents);

        }
        updateState().catch(err => console.error(err));
    }, []);

    async function fetchContent() {
        const response = await fetch(`${apiDomain}/wp-json/wp/v2/posts${category}&per_page=100`);
        return await response.json();
    }

    return data;
}
